  // ImageSlider.js
  import React ,{useState} from 'react';
  import Slider from 'react-slick';
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';
  import CustomPrevArrow from './CustomPrevArrow';
  import { Link } from 'react-router-dom';
  import '../App.scss'
  import '../responsive.scss'


  const ImageSlider = ({data,currentPackage}) => {
    // const [array, setarray] = useState([])
    // console.log(data)
    const sliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000, // Adjust the autoplay speed as needed
    
      // Arrows and Dots
      arrows: true, // Show/Hide arrows
      dots: false, 
      prevArrow: <CustomPrevArrow />, // Custom previous arrow component
      nextArrow: <CustomPrevArrow />, // Custom next arrow component,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
        // Add more breakpoints and settings as needed
      ]
      
    };
    const array = data.filter((destinationObject) => destinationObject.destination !== currentPackage);
       
       
     
console.log(currentPackage,array)
    return (
      <>
       {array.length?  <h2 className='fontBold heading'>Featured Location</h2>:null}  
      <Slider {...sliderSettings}>
      {array.length?
        array.map((destinationObject, index) => (
          <div className='placePackage display_flex_col ' key={index} > <Link to={`/holidays/${destinationObject.place}/${destinationObject.destination}`} > 
              <img src={`../../${destinationObject.imgSrc}`} alt={destinationObject.altText} />
              <div className="display_flex">
                <strong className='place fontBold font24' style={{textTransform:"capitalize"}}>{destinationObject.destination}</strong>
                <p className='duration'>{destinationObject.duration}</p>
              </div>
              <div className="display_flex">
                <p className='place' style={{textTransform:"capitalize"}}>{destinationObject.place}</p>
                <p className='price font24'>₹{destinationObject.price}</p>
    
              </div>
              </Link>
            </div>
          
        ))
        :null} 
      </Slider>
        </>
    );
  };

  export default ImageSlider;
