// ImageSlider.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomPrevArrow from './CustomPrevArrow';
import { Link } from 'react-router-dom';

const ImageSlider = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the autoplay speed as needed

    // Arrows and Dots
    arrows: true, // Show/Hide arrows
    dots: false,
    prevArrow: <CustomPrevArrow />, // Custom previous arrow component
    nextArrow: <CustomPrevArrow />, // Custom next arrow component

  };

  const data = {
    kazakhstan: [
      {
        place: "kazakhstan",
        imgSrc: "../images/touristPlace5.png",
        altText: "kazakhstan",
        destination: "kazakhstan"
      }
    ],
    rajasthan: [
      {
        place: "india",
        imgSrc: "../images/touristPlace9.png",
        altText: "rajasthan",
        destination: "rajasthan"
      }
    ],
    thailand: [
      {
        place: "thailand",
        imgSrc: "../images/touristPlace8.png",
        altText: "thailand",
        destination: "thailand"
      }
    ],
    kerala: [
      {
        place: "india",
        imgSrc: "../images/touristPlace11.png",
        altText: "kerala",
        destination: "kerala"
      }
    ],
    lakshdweep: [
      {
        place: "india",
        imgSrc: "../images/touristPlace10.png",
        altText: "lakshdweep",
        destination: "lakshdweep"
      }
    ],
  }
  const allImgSrc = Object.values(data).flatMap((arr) =>
  arr.map((item) => item)
);
  let array;


  return (
    <Slider {...sliderSettings}>
      {allImgSrc.map((src, index) => (
       
        <Link to={`/holidays/${src.place}/${src.destination}`} style={{borderRadius:"10px"}}>

  <img key={index+1} src={src.imgSrc} alt={`Image ${index+1}`} />
        </Link>
))}
    </Slider>

    // console.log(item.imgSrc)
    // let destinationArray;
    // <p></p>
  );
};

export default ImageSlider;
