import React from 'react'
import { useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

function Navbar() {
  const [isMenuDisplayed, setMenuDisplayed] = useState(false);
  const [url, seturl] = useState(window.location.href);
  const [isDropDown, setDropDown] = useState(false);
  const [issubDropDown, setsubDropDown] = useState(false);
  const [isArroWActive, setArrowActive] = useState(false);
  const [isArroWActive2, setArrowActive2] = useState(false);
  const burgerRef = useRef(null);
  const handleBurgerClick = () => {
    const burgerElement = burgerRef.current;
    console.log('Burger icon clicked!', burgerElement);
    // Add your logic here
    burgerElement.classList.toggle("active")
    setMenuDisplayed(!isMenuDisplayed);
    document.body.style.overflowY = isMenuDisplayed ? 'auto' : 'hidden';
  };
  const scroll = () => {
    const burgerElement = burgerRef.current;
    burgerElement.classList.remove("active")

    document.body.style.overflowY = 'auto';
    setMenuDisplayed(!isMenuDisplayed)
    // const mobElement = mobDisplayRef.current;
    setDropDown(!isDropDown);

    // mobElement.classList.remove("show")


  }
  const handleDropDown = () => {
    console.log(window.location.href, url)
    setDropDown(!isDropDown);
    setArrowActive(!isArroWActive);
  };
  const handlesubDropDown = () => {
    // console.log(window.location.href,url)
    setsubDropDown(!issubDropDown);
    setArrowActive(!isArroWActive2);
  };
  return (

    <>
      <header>
        <nav >
          <div className=" display_flex desk-menu">
            <Link to={'/'} >
              <img src="../images/logowhite.svg" alt="Passtr Logo " className="logo" />
            </Link>
            <div className="display_flex">
              <li><Link to={'/'}  >Home</Link></li>
              <li className='dropDownLi'><Link >Tour Packages<img src="images/down-arrow.png" className={`downArrow ${isArroWActive ? 'active' : ''}`} alt="" onClick={handleDropDown} /></Link>
                <div className={`dropDown ${isDropDown ? 'show' : ''}`}>
                  <ul>
                    {/* <li >Tour Packages<img src="icons/subdown-arrow.png" style={{ width: "15px", marginLeft: "10px" }} className={` ${isArroWActive2 ? 'active' : ''}`} alt="" onClick={handlesubDropDown} /> */}
                      {/* <div className={`subdropDown ${issubDropDown ? 'show' : ''}`}> */}
                        <ul>
                          <li><Link to={`/holidays/india`} onClick={() => setDropDown(false)}>India</Link></li>
                          <li><Link to={`/holidays/kazakhstan`} onClick={() => setDropDown(false)}>Kazakhstan</Link></li>
                          <li><Link to={`/holidays/europe`} onClick={() => setDropDown(false)}>Europe</Link></li>
                          <li><Link to={`/holidays/singapore`} onClick={() => setDropDown(false)}>Singapore</Link></li>
                          <li><Link to={`/holidays/thailand`} onClick={() => setDropDown(false)}>Thailand</Link></li>
                        </ul>
                      {/* </div> */}
                    {/* </li> */}
                  </ul>
                </div></li>

              <li><Link to={`/flights`} >Flights</Link></li>
              <li><Link to={`/hotels`} >Hotels</Link></li>
              <li><Link to={`/taxis`} >Taxis</Link></li>
              <li ><Link to={`/adventure`} >Adventure</Link></li>
              <li><Link to={'/offers'} >Offers</Link></li>
              <li className="callBtn "><Link to={'/contact'} >Contact Us</Link></li>
            </div>
          </div>
          <div className=" display_flex mobile-menu">
            <div id="burger" ref={burgerRef} onClick={handleBurgerClick}>
              <div className="burger-bar"></div>
              <div className="burger-bar"></div>
              <div className="burger-bar"></div>
            </div>
            <div className="display_flex_col">
              <img src="../../images/logoWhite.svg" style={{ width: "120px" }} alt="" className="logo" />
            </div>
          </div>
          <ul className={`mob-display ${isMenuDisplayed ? 'show' : ''}`}>
            <div className="display_flex_col">
              <li><Link className='colorWhite' onClick={scroll} to={'/'} >Home</Link></li>
              <li ><Link className='colorWhite'  >Tour Packages<img src="../images/down-arrow.png" className={`downArrow ${isArroWActive ? 'active' : ''}`} alt="" onClick={handleDropDown} /></Link>
                <div className={`dropDown ${isDropDown ? 'show' : ''}`}>
                  <ul>
                    {/* <li >Tour Packages<img src="icons/subdown-arrow.png" style={{ width: "15px", marginLeft: "10px" }} className={` ${isArroWActive2 ? 'active' : ''}`} alt="" onClick={handlesubDropDown} /> */}
                      {/* <div className={`subdropDown ${issubDropDown ? 'show' : ''}`}> */}
                        <ul>
                          <li><Link to={`/holidays/india`} onClick={scroll}>India</Link></li>
                          <li><Link to={`/holidays/kazakhstan`} onClick={scroll}>Kazakhstan</Link></li>
                          <li><Link to={`/holidays/europe`} onClick={scroll}>Europe</Link></li>
                          <li><Link to={`/holidays/singapore`} onClick={scroll}>Singapore</Link></li>
                          <li><Link to={`/holidays/thailand`} onClick={scroll}>Thailand</Link></li>
                        </ul>
                      {/* </div> */}
                    {/* </li> */}
                  </ul>
                </div>
              </li>
              <li><Link to={`/flights`} onClick={scroll} className='colorWhite'>Flights</Link></li>
              <li><Link to={`/hotels`} onClick={scroll} className='colorWhite'>Hotels</Link></li>
              <li><Link to={`/taxis`} onClick={scroll} className='colorWhite'>Taxis</Link></li>
              <li ><Link to={`/adventure`} onClick={scroll} className='colorWhite'>Adventure</Link></li>
              <li><Link to={'/offers'} onClick={scroll} className='colorWhite' >Offers</Link></li>
              <li className="callBtn "><Link to={'/contact'} onClick={scroll}>Contact Us</Link></li>
            </div>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Navbar