import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';

import Home from './components/Home';
import Cities from './components/Cities';
import About from './components/About';
import Offers from './components/Offers';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Contact from './components/Contact';
import Flights from './components/Flights';
import Adventure from './components/Adventure';
import Taxis from './components/Taxis';
import Hotels from './components/Hotels';
import Holidays from './components/Holidays';
// import ScrollToTopOnNavigation from './components/ScrollTop';
import Form2 from './components/Form2';
// import Donot from './components/donot';
import PackageDetails from './components/Packagedetails';
import './App.scss'
import './responsive.scss'
import SearchBar from './components/SearchBar';
import ScrollToTop from './components/ScrollTop';
import Dropdown from './components/dropdown'

const App = () => {
  return (
    <div className="App">
      <Router >

      <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/cities" element={<Dropdown />} />
          {/* <Route path="/donot" element={<Donot/>} /> */}
          <Route exact path="/holidays/:place" element={<Holidays />} />
          <Route exact path="/holidays/:place/:destination" element={<PackageDetails />} />
        {/* <Route path="/eventShow/:pageId" element={<EventShow />} /> */}
          <Route exact path="/about" element={<About />} />
          <Route exact path="/adventure" element={<Adventure />} />
          <Route exact path="/taxis" element={<Taxis />} />
          <Route exact path="/flights" element={<Flights />} />
          <Route exact path="/hotels" element={<Hotels />} />
          <Route exact path="/searchBar" element={<SearchBar />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/termsconditions" element={<TermsConditions />} />
          <Route exact path="/form2" element={<Form2 />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
