// ImageSlider.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomPrevArrow from './CustomPrevArrow';
import { Link } from 'react-router-dom';

const ImageSlider = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the autoplay speed as needed

    // Arrows and Dots
    arrows: true, // Show/Hide arrows
    dots: false,
    prevArrow: <CustomPrevArrow />, // Custom previous arrow component
    nextArrow: <CustomPrevArrow />, // Custom next arrow component

  };

  const data = [
    '../images/home1.jpg',
  //  '../images/home2.jpg',
   '../images/home3.jpg',
   '../images/home4.jpg',
   '../images/home5.jpg',
  ]
  // const allImgSrc = Object.values(data).flatMap((arr) =>
  // arr.map((item) => item)
// );
  let array;


  return (
    <Slider {...sliderSettings} >
      {data.map((src, index) => (

  <img key={index+1} src={src} alt={`Image ${index+1}`}/>
))}
    </Slider>

    // console.log(item.imgSrc)
    // let destinationArray;
    // <p></p>
  );
};

export default ImageSlider;
