import React from 'react'

function Banner({imgData}) {
  return (
    <>
    <img src={imgData} style={{width:"100%",maxHeight:"900px"}} alt="" />
    </>
  )
}

export default Banner