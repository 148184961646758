import React,{useState,useEffect} from 'react'
import Navbar from './Navbar'
import Banner from './Banner'
import HomeSlider from './HomeSlider'
import Slider from './Slider'
import Footer from './Footer'
import '../App.scss'
import '../responsive.scss'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'

function Home() {

  const linkStyle = {
    position: window.innerWidth > 768?'absolute':'relative',
    top: window.innerWidth > 1000 ? '40px' :window.innerWidth > 768?'15px':'0' ,
    right: '0',
    marginRight:"5px"
  };
  return (
    <>
      <Navbar />
      <div className="firstSection">

      {/* <Banner imgData={'../images/banner.jpg'}/> */}
      <HomeSlider/>
      <div className="passtraHolidays">
        <h1 style={{color:"#4891D4"}}>Passtra Holidays</h1>
        <p style={{color:"#00284c"}}>Unlock the door to adventure with us, where every mile is a brushstroke on the canvas of your life's journey.</p>
      </div>
      <SearchBar/>
      </div>
      <section className='secondSection section display_flex container'>
        <div className="display_flex_col my-5" style={{border:"1px solid #CECECE",borderRadius:"5px"}}>
          <img src="../icons/icon1.png" alt="Earth Icon" />
          <strong className='my-5'>35+</strong>
          <p>Travel Experiences</p>
        </div>
        <div className="display_flex_col my-5"  style={{border:"1px solid #CECECE",borderRadius:"5px"}}>
          <img src="../icons/icon2.png" alt="Earth Icon" />
          <strong className='my-5'>5+</strong>
          <p>Countries</p>
        </div>
        <div className="display_flex_col my-5"  style={{border:"1px solid #CECECE",borderRadius:"5px"}}>
          <img src="../icons/icon3.png" alt="Earth Icon" />
          <strong className='my-5'>Best Price</strong>
          <p>Guaranteed</p>
        </div>
        <div className="display_flex_col my-5"  style={{border:"1px solid #CECECE",borderRadius:"5px"}}>
          <img src="../icons/icon4.png" alt="Earth Icon" />
          <strong className='my-5'>30+</strong>
          <p>Happy Travellers</p>
        </div>
      </section>
      <section className="thirdSection section display_flex">
        <div className="firstDiv">
          <h2 className='heading'>Find the Perfect Place for you.</h2>
          <p className='subheading'>Embark on an Adventure to Discover Your Ideal Sanctuary: A Quest for the Perfect Haven Awaits!</p>
          <Link to={'/contact'} className='btn'>Find Now</Link>
        </div>
        <div className="secondDiv">
          <Slider />
        </div>

      </section>
      <section className="fourthSection section display_flex_col container" style={{position:"relative"}}>
        <h2 className="heading fontBold" id="exploreHeading">Explore Lakshdweep</h2>
        <Link
        to={`holidays/india/lakshdweep`}  className='btn' style={linkStyle}>Visit Us</Link>

        {/* <p className="subheading">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, odit.</p> */}
        <div className="display_flex">
        <img src="../images/lakshdweep/lakshdweep.webp" alt="" />
        <img src="../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp" alt="" />
        <img src="../images/lakshdweep/lakshadweeptourism.webp" alt="" />
        <img src="../images/lakshdweep/outlooktraveller.webp" alt="" />
        </div>
      </section>
      <section className="fifthSection section display_flex_col container">
        <h2 className="heading fontBold">Popular Places</h2>
        <p className="subheading">Discover the world's gems with our curated list of must-visit places – where every destination is a story waiting to be told.
</p>
        <div className="display_flex">

        <div className="display_flex" style={{position:"relative"}}>
          <img src="../images/places12.webp" alt="" />
          <Link to= {`/holidays/india/lakshdweep`} className="display_flex hoverDiv">
            <p className="fontBold">Lakshdweep</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
        </div>
        <div className="display_flex ">

        <div className="" style={{position:"relative"}}>
          
          {/* <Link to={`/holidays/uttrakhand`} >Uttrakhand</Link> */}
          <img src="../images/places13.jpg" alt="" />
          <Link to= {`/holidays/kazakhstan`} className="display_flex hoverDiv">
            <p className="fontBold">Kazakhstan</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
          </div>
        <div className="" style={{position:"relative"}}>
          
          <img src="../images/places14.webp" alt="" />
          <Link to= {`/holidays/europe`} className="display_flex hoverDiv">
            <p className="fontBold">Europe</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
          </div>
        <div className="" style={{position:"relative"}}>
          
          <img src="../images/places4.png" alt="" />
          <Link to= {`/holidays/india`} className="display_flex hoverDiv">
            <p className="fontBold">Leh</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
          </div>
        <div className="" style={{position:"relative"}}>
          
          <img src="../images/places5.png" alt="" />
          <Link to= {`/holidays/india`} className="display_flex hoverDiv">
            <p className="fontBold">Lakshdweep</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
          </div>
        <div className="" style={{position:"relative"}}>
          
          <img src="../images/places6.png" alt="" />
          <Link to= {`/holidays/india`} className="display_flex hoverDiv">
            <p className="fontBold">Kerala</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
          </div>
        <div className="" style={{position:"relative"}}>
          
          <img src="../images/places7.png" alt="" />
          <Link to= {`/holidays/india`} className="display_flex hoverDiv">
            <p className="fontBold">Kashmir</p>
            <img src="../icons/right-arrow.png" alt="" />
          </Link>
          </div>

        </div>
        </div>
      </section>
      <section className="sixthSection section display_flex_col" style={{background:"#EDF6FF"}}>
        <div className="container display_flex_col" >
        <h2 className="heading fontBold">Why Choose Us?</h2>
        {/* <p className="subheading">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis.</p> */}
        <div className="display_flex">

        <div className="display_flex_col">
          <div className="display_flex">
            <img src="../icons/planning.png" alt="" />
            <div className="display_flex_col">
              <strong className='fontBold'>Transparent pricing & Value for money</strong>
              <p>We're all about transparent pricing, no hidden costs. Get maximum value for your money with quality and affordable travel experiences. Trust us to make your budget go the extra mile.</p>
            </div>
          </div>
          <div className="display_flex">
            <img src="../icons/guidance.png" alt="" />
            <div className="display_flex_col">
              <strong className='fontBold'>Dedicated Customer Support</strong>
              <p>We go beyond being a travel agency – we're your travel companion. Our customer support ensures a seamless journey. From pre-trip questions to on-the-road assistance, Passtra is with you at every step.</p>
            </div>
          </div>
          <div className="display_flex">
            <img src="../icons/support.png" alt="" />
            <div className="display_flex_col">
              <strong className='fontBold'>Expert Guidance </strong>
              <p>Explore new destinations with confidence. Passtra's seasoned travel experts offer expert advice, insider tips, and customer support, ensuring you feel supported at every stage. From pre-trip to on-the-road, we've got you covered.</p>
            </div>
          </div>
          <div className="display_flex">
            <img src="../icons/value.png" alt="" />
            <div className="display_flex_col">
              <strong className='fontBold'>Seamless & Stress-free planning </strong>
              <p>Let us handle the details. From visa assistance to accommodation, Passtra ensures a stress-free travel experience. Focus on making memories; we've got the logistics covered.</p>
            </div>
          </div>
          
        </div>
        <div className="display_flex">
        <img src="../images/places11.png" alt="" className='my-5' />
        <img src="../images/places8.png" alt="" className='my-5' />
        <img src="../images/places10.png" alt="" className='my-5' />
        <img src="../images/places9.png" alt="" className='my-5' />
        

        </div>
        </div>
        </div>
      </section>
      {/* <section className="seventhSection section display_flex_col container" >
        <h2 className="heading fontBold">Our Partners</h2>
        <div className="display_flex"><img src="../images/partners1.png" alt=""  className='my-20'/><img src="../images/partners2.png" alt=""  className='my-20'/><img src="../images/partners3.png" alt="" className='my-20' /></div>
      </section> */}
      <Footer/>
    </>
  )
}

export default Home