import React from 'react'
import Form from './Form'
import Navbar from './Navbar2'
import Footer from './Footer'
import Banner from './Banner'

function Taxis() {
  return (
    <>
      <Navbar />
      <Banner imgData={'../images/taxi.webp'}/>
      <div className=" section">
        <div className="display_flex ">

          <Form pickup={true} destination={true} days={true}/>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Taxis