import React, { useEffect, useRef, useState } from 'react'

// import {data} from "../data";
import { Link } from "react-router-dom";
import Navbar2 from "./Navbar2";
import Footer from "./Footer";
import { useParams, useLocation } from "react-router-dom";
import { data } from '../data'
import Form from './Form';
import Accordion from './Accordion';
import Table from './Table';
import Breadcrumb from '../Breadcrumb';
import Slider from './Slider2';
import ImageGallery from './ImageGallery';
// import CustomPrevArrow from './CustomPrevArrow';

const PackageDetails = () => {
  const sectionRef = useRef(null);
  const [isFormVisible, setFormVisible] = useState(false);

  const toggleFormVisibility = () => {
      setFormVisible(!isFormVisible);
      // Disable scrolling when the form is visible
      document.body.style.overflow = isFormVisible ? 'auto' : 'hidden';
  };

  // Event handler for button click
  const handleButtonClick = () => {
    if (window.innerWidth > 768) {
      toggleFormVisibility();

    }
    else{
      scrollToSection();

    }
    // Call your existing onClick event function for mobile responsiveness
    // This assumes you already have a function named `handleMobileButtonClick`
    // Replace it with your actual function name
    // Toggle the visibility of the form
};

  const scrollToSection = () => {
    // Scroll to the section
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  // let planDiv = useRef(null)
  // const handleImageClick = (newImage) => {
  //   setCurrentImage(newImage);
  // };
  const { place, destination } = useParams();
  
  function findDestinationArray(place) {
    const foundArray = Object.keys(data).find((key) => key === place);
    if (foundArray) {
      return data[foundArray];
    } else {
      return null;
    }
  }
  const cardsArray = findDestinationArray(place);
  console.log(cardsArray,place)
  let destinationArray
  let details;
  const findDetailsByDestination = (searchDestination) => {
    let matchingDetails = [];

    Object.keys(data).forEach((destinationKey) => {
      destinationArray = data[destinationKey];

      destinationArray.forEach((destinationObject) => {
        if (destinationObject.destination === searchDestination) {
          details = destinationObject.details;

          // if (details && typeof details === 'object') {
          //   // Flatten the details object into an array of key-value pairs
          //   matchingDetails = Object.entries(details).map(([key, value]) => ({
          //     key,
          //     value,
          //   }));
          // }
        }
      });
    });

    return matchingDetails;
  }
  // Example: Find details for the destination received from useParams()
  const detailsForDestination = findDetailsByDestination(destination);

  console.log(details)
  const accordionItems = [
    {
      title: 'Day 1',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse augue massa, mollis sit amet sagittis vitae, mattis eu quam. Ut vel sagittis elit, non viverra nisl. Sed iaculis pretium lectus ac dictum',
      content2: 'Maecenas imperdiet lacus quis aliquet facilisis Donec pharetra felis imperdiet, posuere purus varius, viverra turpis.',
    },
    {
      title: 'Day 2',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse augue massa, mollis sit amet sagittis vitae, mattis eu quam. Ut vel sagittis elit, non viverra nisl. Sed iaculis pretium lectus ac dictum',
      content2: 'Maecenas imperdiet lacus quis aliquet facilisis Donec pharetra felis imperdiet, posuere purus varius, viverra turpis.',
    },
    {
      title: 'Day 3',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse augue massa, mollis sit amet sagittis vitae, mattis eu quam. Ut vel sagittis elit, non viverra nisl. Sed iaculis pretium lectus ac dictum',
      content2: 'Maecenas imperdiet lacus quis aliquet facilisis Donec pharetra felis imperdiet, posuere purus varius, viverra turpis.',
    },
  ];
  //   const createTable = (sampleData) => {
  //     const table = document.createElement('table');

  //     // Create the header row
  //     const headerRow = document.createElement('tr');
  //     for (let col = 1; col <= 7; col++) {
  //         const th = document.createElement('th');
  //         th.textContent = `Column ${col}`;
  //         headerRow.appendChild(th);
  //     }
  //     table.appendChild(headerRow);

  //     // Create the data rows
  //     for (let row = 0; row < sampleData.length; row++) {
  //         const dataRow = document.createElement('tr');
  //         for (let col = 0; col < sampleData[row].length; col++) {
  //             const td = document.createElement('td');
  //             td.textContent = sampleData[row][col];
  //             dataRow.appendChild(td);
  //         }
  //         table.appendChild(dataRow);
  //     }
  //     // Append the table to the body

  // };
  return (
    <>
      <Navbar2 />
      <div className="container">
        <Breadcrumb />
      </div>{
        details ?<section className=' display_flex_col packagePage container'>
        <div className="display_flex ">
          <div className="display_flex">
            <div className="display_flex packageHeading">
              <div className="display_flex_col">

              <strong className='fontBold' style={{textTransform:"capitalize"}}>Majestic {destination} Package</strong>
              <p><span>{details.days}</span> Days <span>{details.nights}</span> Nights</p>
              </div>
              <div className="display_flex_col priceHeading">
              <p className="pricePerPerson fontBold" style={{margin:"10px 0"}}>
              ₹{details.pricePerPerson} / <span style={{fontSize:"18px"}}>{details.person}</span> 
              </p>
              <button className="bookNow btn" onClick={handleButtonClick} >Book Now</button>
            </div>
            </div>
            <div className="display_flex_col">
              <ImageGallery mainImg={details.mainImg}
        moreImg1={details.moreImg1}
        moreImg2={details.moreImg2}
        moreImg3={details.moreImg3}
        moreImg4={details.moreImg4}
        moreImg5={details.moreImg5}/>
              {/* <img src={details.mainImg} alt="" className="mainImg" />
              <div className="display_flex">
                <img src={details.moreImg1} alt="" className="moreImg"  onClick={() => handleImageClick(moreImg1)}/>
                <img src={details.moreImg2} alt="" className="moreImg"/>
                <img src={details.moreImg3} alt="" className="moreImg"/>
                <img src={details.moreImg4} alt="" className="moreImg"/>
                <img src={details.moreImg5} alt="" className="moreImg"/>
              </div> */}
            </div>
          </div>
          {/* <div className="display_flex">
            <div className="display_flex_col">


            <div className="display_flex priceHeading">
              <p className="pricePerPerson fontBold">
              ₹{details.pricePerPerson} / <span style={{fontSize:"18px"}}>{details.person}</span> 
              </p>
              <button className="bookNow btn" onClick={scrollToSection}>Book Now</button>
            </div>
            <div className="display_flex deskForm">

            </div>
          </div>
          </div> */}
        </div>
        <div className="display_flex section">
          <div className="display_flex">
                {details.note?<div className="display_flex_col inclusionDiv my-20" ><ul><li className='fontBold'>
              {details.note}</li></ul>
            </div>:null}
              
            <div className="display_flex_col inclusionDiv my-20" >
              <h3 className='fontBold heading24 subheading'>Inclusions</h3>
              <ul>
                
                {details.inclusion.map((item,index)=><li key={index} style={{margin:"10px 0"}}>{item}</li>)}
              </ul>
            </div>
            <div className="display_flex_col inclusionDiv my-20" >
              <h3 className='fontBold heading24 subheading'>Exclusions</h3>
              <ul>
                
                {details.exclusion.length>0?details.exclusion.map((item,index)=><li key={index} style={{margin:"10px 0"}}>{item}</li>):<li style={{margin:"10px 0"}}>{details.exclusion}</li>}
              </ul>
            </div>
            {/* <div className="display_flex_col my-20 " style={{width:"100%"}}>
              <h3 className='fontBold heading24 subheading'>Plan</h3>
             {details.accordionItems? <Accordion items={details.accordionItems}/>:null}
            </div> */}
          </div>
          <div className="display_flex_col">
            <h3 className='fontBold heading24 subheading'>Description</h3>
            {details.description?.map(item=>
            <p>{item.describe}</p>
             )}
            <br />
            {details.description.slice(1).map(item=>
              (<ul style={{margin:"10px 0"}}><p className='fontBold' >{item.ul}</p>
              <li>{item.li}</li>
              </ul>
              ))}
            
          </div>
        </div>
        <div className="display_flex mobileForm"ref={sectionRef}>

            <Form />
        </div>
     
      <div className="display_flex">
        {details.tableData?<Table data={details.tableData}/>:null}
      </div>
    
        <div className="packagePageSection featuredLocation  section">
          <div className="display_flex_col">
          </div>
        </div>
          {/* <h2 className='fontBold heading featured'>Featured Location</h2> */}

      {/* <p className='subheading'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus voluptatem esse cumque?</p> */}
      <Slider data={cardsArray} currentPackage={destination} />

    </section >:
  'No package Available fo this location'
}

{/* <div className="display_flex"> */ }


{/* </div> */ }
<Footer />
{isFormVisible && (
<div className="display_flex deskForm">
<Form className="deskForm" />
<img src="../../icons/close.png" alt="" className='close' onClick={toggleFormVisibility} style={{position:"absolute",}}/>
</div>
)}

    </>

  );
};



export default PackageDetails