import React from 'react'
import Navbar2 from './Navbar2'
import Banner from './Banner'
import Footer from './Footer'

function about() {
  return (
    <>
    <Navbar2/>
    <Banner imgData={'../images/banner2.png'}/>
    <section className="section display_flex container">
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, exercitationem ipsam omnis eveniet fuga voluptates sit ducimus, id odio veniam consequuntur cumque consequatur accusamus facilis eligendi ipsum expedita consectetur minus earum reiciendis hic. Illo magnam necessitatibus soluta totam placeat officia!Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse error deserunt fugiat! Labore commodi dignissimos alias quisquam nam ad qui quasi, incidunt aliquid, atque tenetur recusandae. Atque nemo modi harum. Perspiciatis minus ullam perferendis animi temporibus optio, saepe nam necessitatibus natus dolorem sed velit rem porro repellat aperiam? Velit, eaque nulla? Eos itaque culpa earum, aspernatur commodi quod aliquid nemo velit explicabo adipisci voluptatibus accusantium laudantium. Totam.</p>
        <br />
        <ul style={{width:"100%"}}>
            <li className='my-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li className='my-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li className='my-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li className='my-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
            <li className='my-5'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
            
        </ul>
    </section>
    <Footer/>
    </>

  )
}

export default about