export const data = {
  india: [
    {
      id: 1,
      place: "india",
      imgSrc: "../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
      altText: "lakshdweep",
      destination: "lakshdweep",
      duration: "2D/3N",
      price: "1,15,000",
      heading: "heading1",
      details: {
        mainImg: "../../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
        moreImg1: "../../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
        moreImg2: "../../images/lakshdweep/lakshadweeptourism.webp",
        moreImg3: "../../images/lakshdweep/lakshdweep.webp",
        moreImg4: "../../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
        moreImg5: "../../images/lakshdweep/outlooktraveller.webp",
        days: '5',
        nights: '4',
        person: '2 Adults',
        pricePerPerson: "1,15,000",
        description: [{

          describe: "Offering a 4-night/5-day package to Lakshadweep for 2 adults. The package includes return flights from Delhi to Kochi and from Kochi to Lakshadweep, a 1-night stay in a 3-star hotel in Kochi with breakfast, AC deluxe rooms in Agatti Island for 3 nights, welcome drink at the resort, and breakfast, lunch, and dinner on Agatti Island. ",
        },
        {
          ul: "",
          li: "The package also includes morning and evening tea with snacks, sightseeing in Agatti Village, a visit to Thinnakara Island by boat, a shipwreck visit, a visit to the sandbank near Bangaram, a glass boat ride, a visit to Kalpitti Island, and airport transfers at Agatti Island.ding"
        },
        {
          ul: "",
          li: "Water sports and any additional sightseeing are not included in the package. The package price is INR 1,15,000 inclusive of all taxes for 2 adults"
        },
        ],
        inclusion: [
          'Delhi to Kochi to Lakshadweep Return flight',
          '3 star Hotel in Kochi for 1 Night with Breakfast',
          'Permit Cost for Lakshadweep',
          'AC Deluxe rooms in Agatti Island for 3N Four days',
          'Welcome Drink at the resort',
          'Breakfast, Lunch, and Dinner on Agatti Island',
          'Morning and Evening Tea with Snacks',
          'Agatti Village Sightseeing ',
          'Thinnakara Island visit by boat',
          'Shipwreck visit',
          'Sandbank near Bangaram, Glass Boat ride',
          'Kalpitti Island visit',
          'Airport transfer at Agatti Island'
        ],
        exclusion: ['Water sports and any sightseeing not mentioned in the itinerary', 'Personal expenses'],
        accordionItems: [
          {
            title: 'Day 1 ',
            content: 'Morning: Take a flight from Delhi to Kochi. Afternoon: Arrive in Kochi and spend the day exploring Fort Kochi, visiting the Chinese',
            content2: 'Fishing Nets, St. Francis Church, and Mattancherry Palace. Evening: Enjoy a local dinner and relax in Kochi'
            ,
          },
          {
            title: 'Day 2',
            content: 'On arrival at Agatti airport, our representative will receive and escort you to the Hotel. After lunch take rest for 3 hours at the hotel and by 5 o clock proceed for Agatti Island sightseeing',
            content2: 'which includes – South Beach, Eastern jetty (ship embarkation and disembarkation point), NIOT sea water desalination Plant, Lagoon beach a sunset point, relaxation in the creamy sand for a couple of hours and back to Hotel around 7:00 pm, dinner will be served at 8:30 pm.- Overnight stay at Agatti',
          },
          {
            title: 'Day 3',
            content: 'Early morning after breakfast around 9 am proceed to Thinnakara Island, beautiful uninhabited islands, Tinnakara islands, in a single atoll, lying in the northern side of Agatti. Tour by private boat from Agatti 45 minutes Journey. The trip provides beautiful sandy beaches and swimming with turtles, snorkeling in blue shallow water.',
            content2: 'After that boat will move to shipwreck snorkeling and then to Sand banks near Bangaram Island. After 5:30 pm the boat moves to Agatti Hotel, relaxes in the room, and dinner will be served at 8:30 pm. Please note movement of the boat to Thinnakara depends on high tide and low tide of the sea level. Overnight stay at Agatti.',
          },
          {
            title: 'Day 4',
            content: 'After the Morning breakfast around 9 AM enjoy the Glass bottom boating in the lagoon for 1 hour. And Enjoy the water sports activities like the Banana ride. Kayak, Snorkelling, and other water sports activities are available on extra payment. Lunch will be served from 12:30 to 2.00 PM.',
            content2: 'After 4:30 pm proceed to Kalpitti Island or Turtle Watch, a small islet on the southern tip of Agatti, which offers swimming and turtle watch, The journey by a small boat in shallow water,sightseeing in the island by walk, after sightseeing Kalpitti Island return to Agatti around 6:30 pm. overnight stay at Agatti.'
          },
          {
            title: 'Day 5',
            content: 'This is the final day at Agatti, Morning after breakfast proceeds to finish departure formalities and proceed to the Airport,',
            content2: 'for a return journey to Kochi by Air and from Kochi to Delhi by Air.',
          },
        ]
      },
    },
    {
      id: 2,
      place: "india",
      imgSrc: "../images/kerala/kerala1.avif",
      altText: "kerala",
      destination: "kerala",
      duration: "5D/4N",
      price: "30,900",
      heading: "heading1",
      details: {
        mainImg: "../../images/kerala/kerala1.jpg",
        moreImg1: "../../images/kerala/kerala2.jpg",
        moreImg2: "../../images/kerala/kerala3.jpg",
        moreImg3: "../../images/kerala/kerala4.jpg",
        moreImg4: "../../images/kerala/kerala5.jpg",
        moreImg5: "../../images/kerala/kerala6.jpg",
        days: '5',
        nights: '4',
        person: 'person (Twin Sharing Basis)',
        pricePerPerson: "30,900",
        description: [{

          describe: "Experience the tranquil beauty of Kerala with our Kerala Tour package, carefully crafted to immerse you in the rich culture and natural wonders of this southern paradise. Begin your journey with a flight to Kochi, where you'll be welcomed by the warm hospitality of Kerala. ",
        },
        {
          ul: "",
          li: "Glide along the backwaters of Alleppey on a traditional houseboat, savoring delicious local cuisine amidst serene surroundings."
        },
        {
          ul: "",
          li: "Then, journey to Munnar to explore misty tea plantations and cascading waterfalls. With comfortable accommodations and expertly planned itineraries, let us guide you through an unforgettable adventure in Kerala."
        },
        ],
        inclusion: [
          'Delhi to Kochi Return Flight',
          'Airport to Alleppey Houseboat',
          'Alleppey Houseboat cruise and night stay with Breakfast, Lunch and Dinner',
          'Alleppey to Munnar Transfer by Taxi',
          'Hotel Accommodation in Munnar for 3 Nights with breakfast',
          'Munnar to Kochi Airport by Taxi'

        ],
        exclusion: ['Local transport, sightseeing, meals, and anything not mentioned above. '],
        accordionItems: [
          {
            title: 'Day 1: Arrival in Cochin and Transfer to Alleppey',
            content: 'Arrive in Cochin (Kochi) and transfer to Alleppey. ',
            content2: 'Check-in to your houseboat for an overnight stay.• Explore the backwaters of Alleppey, enjoy the serene environment, and savor a traditional Kerala dinner on the houseboat.'
            ,
          },
          {
            title: 'Day 2: Alleppey to Munnar',
            content: 'Check out from the houseboat after breakfast and proceed to Munnar (approx. 4-5 hours drive).',
            content2: 'Arrive in Munnar, check in to your hotel, and rest for a while Evening at leisure. You can explore the local markets in Munnar',
          },
          {
            title: 'Day 3: Munnar Sightseeing',
            content: 'Visit the Mattupetty Dam and Echo Point for scenic views. Explore the Tea Gardens and learn about the tea-making process.',
            content2: 'Visit the Rose Garden and Hydel Park. Optional: Visit the Attukal Waterfalls.',
          },
          {
            title: 'Day 4: Munnar Sightseeing',
            content: 'Explore Eravikulam National Park (home to the Nilgiri Tahr). Visit the Anamudi Peak, the highest point in South India.',
            content2: ' Visit the Tea Museum to learn more about tea processing. Relax in the evening and explore local cuisine',
          },
          {
            title: 'Day 5: Departure',
            content: 'Check-out from the hotel and proceed to Cochin for departure. Optional: Visit Fort Kochi if time permits.',
            content2: ' Depart from Cochin with fond memories of your Alleppey and Munnar trip',
          },
        ]
      },
    },
    {
      id: 3,
      place: "india",
      imgSrc: "../images/andaman/andaman1.jpg",
      altText: "andaman",
      destination: "andaman",
      duration: "7D/6N",
      price: "87,700",
      heading: "heading1",
      details: {
        mainImg: "../../images/andaman/andaman1.jpg",
        moreImg1: "../../images/andaman/andaman2.webp",
        moreImg2: "../../images/andaman/andaman3.jpg",
        moreImg3: "../../images/andaman/andaman4.webp",
        moreImg4: "../../images/andaman/andaman5.jpg",
        moreImg5: "../../images/andaman/andaman6.jpg",
        days: '6',
        nights: '7',
        person: '2 Adults',
        pricePerPerson: "87,700",
        description: [{

          describe: "The Andaman Tour-6N/7Days package offers a delightful 7-day adventure for 2 adults, encompassing return flights from Delhi to Port Blair, comfortable 3-star accommodations in Port Blair, Havelock Island, and Neil Island, ferry transfers between the islands, complimentary breakfast, and airport transfers in Delhi and Port Blair.",
        },
        {
          ul: "",
          li: "The itinerary includes visits to picturesque beaches, historical landmarks, museums, and opportunities for water activities such as snorkeling, scuba diving, and glass-bottom boat rides."
        },
        {
          ul: "",
          li: "With a package price of INR 87,700 inclusive of all taxes, this well-rounded tour provides a perfect blend of relaxation, exploration, and cultural immersion in the captivating Andaman Islands."
        },
        ],
        inclusion: [
          'Return Flight from Delhi to Port Blair',
          '3 Star Hotel for 2N in Port Blair',
          'Ferry from Port Blair to Havelock (Swaraj) Island',
          '3 Stat Hotel for 2N in Havelock Island',
          'Ferry from Havelock to Neil (Shaheed Island)',
          '3 Star Hotel for 1 Night in Neil Island ',
          'Ferry from Neil Island to Port Blair ',
          'Hotel for 1N in Port Blair',
          'Free Breakfast',
          'Airport Transfer at Delhi and Portblair'

        ],
        exclusion: ['Local transport, sightseeing and anything not mentioned above. '],
        accordionItems: [
          {
            title: 'Day 1: Arrival in Port Blair',
            content: 'Fly from Delhi to Veer Savarkar International Airport in Port Blair. Check in at the hotel/resort and freshen up. Visit Corbyns Cove Beach for some relaxation and beach time.',
            content2: 'Witness the mesmerizing Sound and Light Show at the Cellular Jail in the evening. Dinner at a local restaurant and overnight stay in Port Blair.'

          },
          {
            title: 'Day 2: Explore Port Blair',
            content: 'Early breakfast and head to the Marina Park and Aquarium.Explore the Anthropological Museum to learn about the indigenous tribes of the islands.',
            content2: ' Visit Chatham Saw Mill, one of the oldest and largest sawmills in Asia.Afternoon visit to Samudrika Naval Marine Museum.Return to the hotel, dinner, and overnight stay in Port Blair',
          },
          {
            title: 'Day 3: Port Blair - Havelock Island',
            content: 'Check out from the hotel after breakfast and proceed to the Havelock Island by a private ferry. Check-in at the resort/hotel upon arrival.',
            content2: ' Relax and explore the beautiful Radhanagar Beach (Beach No. 7), renowned for its crystal-clear waters and stunning sunset views. Dinner and overnight stay in Havelock Island.',
          },
          {
            title: 'Day 4: Havelock Island - Elephant Beach',
            content: 'After breakfast, head to Elephant Beach. Enjoy water activities like snorkeling, scuba diving, or glass-bottom boat rides in the clear waters. Return to the hotel in the afternoon. ',
            content2: ' Leisure time to explore or relax on your own. Dinner and overnight stay in Havelock Island',
          },
          {
            title: 'Day 5 : Havelock Island - Neil Island',
            content: 'Check out from the hotel after breakfast and take a ferry to Neil Island. Check-in at the hotel and relax for a while. ',
            content2: 'Visit the stunning beaches of Neil Island: Bharatpur Beach, Sitapur Beach, and Laxmanpur Beach. Witness the natural rock formations and beautiful sunset at Laxmanpur Beach. Dinner and overnight stay in Neil Island.',
          },
          {
            title: 'Day 6 : Neil Island - Port Blair',
            content: 'Enjoy breakfast and explore more of Neil Island if time permits. Check-out from the hotel and return to Port Blair via ferry. ',
            content2: 'Check in at the hotel and relax for the rest of the day. Shopping at Aberdeen Bazaar for souvenirs and local handicrafts. Farewell dinner and overnight stay in Port Blair',
          },
          {
            title: 'Day 7: Departure from Port Blair',
            content: 'De on the flight timing, spend the morning at leisure or visit any missed attractions. ',
            content2: 'Check-out from the hotel and transfer to the airport for your flight back to Delhi r',
          },
        ]
      },
    },
    {
      id: 4,
      place: "india",
      imgSrc: "../images/rajasthan/udaipurLakePichola.webp",
      altText: "Rajasthan ",
      destination: "rajasthan",
      duration: "7N/8D",
      price: "94,230",
      heading: "heading1",
      details: {
        mainImg: "../../images/rajasthan/jaisalmerBadaBagh.webp",
        moreImg1: "../../images/rajasthan/jaisalmerGadisarLake.webp",
        moreImg2: "../../images/rajasthan/mountAbuDilwaraJainTemples.webp",
        moreImg3: "../../images/rajasthan/udaipurCityPalace.webp",
        moreImg4: "../../images/rajasthan/udaipurSaheliyonKiBari.webp",
        moreImg5: "../../images/rajasthan/mountAbuNakkiLake.webp",
        days: '6',
        nights: '7',
        person: '4 Adults Inc. of all taxes',
        pricePerPerson: "94,230",
        description: [{

          describe: "Embark on a captivating 7-night, 8-day Rajasthan Tour, encompassing the enchanting cities of Udaipur, Mount Abu, and Jaisalmer. This meticulously crafted itinerary offers a perfect blend of historical exploration, architectural marvels, and natural splendor.",
        },
        {
          ul: "",
          li: "From the intricate marble architecture of the Ranakpur Jain Temples to the exquisite carvings at the Dilwara Jain Temples, and the golden hues of Jaisalmer Fort, each day promises a new adventure."
        },
        {
          ul: "",
          li: " Enjoy boat rides on picturesque lakes, witness mesmerizing sunsets, and delve into the vibrant local markets. With flights, comfortable hotel stays, and breakfast included, this all-inclusive package ensures a seamless and unforgettable journey through the heart of Rajasthan."
        },
        ],
        inclusion: [
          'Flights from Delhi to Udaipur and from Jaisalmer to Delhi',
          'Taxi transfers from Udaipur to Mount Abu and from Mount Abu to Jaisalmer',
          '3-star hotel accommodations in Udaipur, Mount Abu, and Jaisalmer',
          'Complimentary breakfast during the stay',
          'Airport transfers for added convenience',
        ],
        exclusion: ['Local transport expenses', 'Sightseeing costs', 'Any other expenses not specified in the inclusions'],
        accordionItems: [
          // {mainTitle:"Day 1-3: Udaipur"},
          {
            title: "Day 1",
            content: '- Arrival in Udaipur and hotel check-in',
            content2: '- Visit to the City Palace to explore its stunning architecture and enjoy scenic views of Lake Pichola Evening boat ride on Lake Pichola to witness the beautiful sunset'

          },
          {
            title: 'Day 2',
            content: '-Visit to Saheliyon Ki Bari, a serene garden with lush greenery, fountains, and marble art Exploration of Jagdish Temple, an ancient Hindu temple dedicated to Lord Vishnu',
            content2: '- Time for wandering through the local markets and shopping for traditional Rajasthani crafts                          ',
          },
          {
            title: 'Day 3 ',
            content: '- Take a day trip to Kumbhalgarh Fort, known for its massive walls and historical significance',
            content2: '- Visit the intricate Ranakpur Jain Temples, featuring stunning marble architecture',
          },
          {
            title: 'Day 4',
            content: 'Travel to Mount Abu (approx. 3-4 hours by road)',
            content2: 'Check into your hotel and relax for the evening.',
          },
          {
            title: 'Day 5',
            content: '- Visit to Dilwara Jain Temples, renowned for their exquisite marble carvings',
            content2: '- Exploration of Nakki Lake and the opportunity to enjoy a boat ride (if available). Visit to the sunset point to witness a mesmerizing sunset view',
          },
          {
            title: 'Day 6',
            content: '- Travel to Jaisalmer (approx. 9 hours by road)',
            content2: 'Check into your hotel and relax.',
          },
          {
            title: 'Day 7',
            content: '- Exploration of Jaisalmer Fort, known for its golden hue and historical importance. Visit to Patwon Ki Haveli and Nathmal Ki Haveli to admire the Rajasthani architecture',
            content2: '- Enjoy a camel safari in the Sam Sand Dunes during the evening',
          },
          {
            title: 'Day 8',
            content: '- Visit to Bada Bagh, a garden complex with royal cenotaphs. Exploration of Gadisar Lake and its surrounding temples and ghats',
            content2: '- Departure from Jaisalmer for your onward journey ',
          },
        ]
      },
    },
    {
      id: 5,
      place: "india",
      imgSrc: "../images/manglore/manglore.png",
      altText: "Manglore",
      destination: "manglore",
      duration: "3N/4D",
      price: " 69,474",
      heading: "heading1",
      details: {
        mainImg: "../../images/manglore/manglore.jpeg",
        moreImg1: "../../images/manglore/manglore1.jpg",
        moreImg2: "../../images/manglore/manglore2.jpeg",
        moreImg3: "../../images/manglore/manglore3.jpeg",
        moreImg4: "../../images/manglore/manglore4.jpeg",
        moreImg5: "../../images/manglore/manglore5.jpeg",
        days: '4',
        nights: '3',
        person: 'Inc. of all Taxes for 2 Adults, 1 Child(15)',
        pricePerPerson: "69,474",
        description: [{

          describe: "Embark on a captivating journey through Mangalore and Udupi during your Ooty Tour-3N/4Days. Begin your adventure in Mangalore, where you'll visit the vibrant Panambur Beach, known for its water sports and stunning sunset views. Explore the nearby Tannirbhavi Beach and admire the exquisite frescoes at the St. Aloysius Chapel, immersing yourself in the local culture and beauty.",
        },
        {
          ul: "",
          li: "In Udupi, experience the picturesque Malpe Beach, offering a range of water sports activities and delectable coastal cuisine at beachside shacks. Venture to St. Mary's Island via a scenic boat ride to marvel at the unique hexagonal basalt rock formations and relax on the pristine beaches. "
        },
        {
          ul: "",
          li: " Visit the renowned Udupi Sri Krishna Temple to seek blessings and wander through the bustling local market to shop for souvenirs and local specialties.Indulge in authentic Udupi cuisine, including mouthwatering dishes like Masala Dosa, Goli Baje, and traditional desserts like Payasam."
        },
        {
          ul: "",
          li: " Whether you're engaging in water sports, exploring cultural landmarks, or savoring local flavors, your journey through Mangalore and Udupi promises unforgettable memories and enriching experiences."
        },
        ],
        inclusion: [
          'Delhi to Mangalore Flight',
          'Mangalore to Delhi flight(1 Stop Bangalore)',
          'Apartment stay in Mangalore for 1 Night',
          'Apartment stay in Udupi for 1 Night',
          'Mangalore to Udupi by Taxi',
          'Udupi to Mangalore Airport by Taxi',
        ],
        exclusion: ['Local transport, sightseeing, meals and anything not mentioned above.'],
        accordionItems: [
          {
            title: "Day-1 Morning: Arrival in Mangalore",
            content: 'Arrive in Mangalore',
            content2: 'Check into your hotel and freshen up'

          },
          {
            title: 'Afternoon: Panambur Beach',
            content: 'Head to Panambur Beach, one of the most popular beaches in Mangalore',
            content2: 'Engage in water sports activities like jet skiing, banana boat rides, parasailing, or surfing (depending on availability and weather conditions). Enjoy a delicious beachside lunch at one of the shacks.',
          },
          {
            title: 'Evening: Relaxation',
            content: 'Spend the evening relaxing at the beach, soaking in the sunset views.',
            content2: ' You can also explore nearby attractions like the Tannirbhavi Beach or visit the St. Aloysius Chapel to admire its beautiful frescoes',
          },
          {
            title: 'Dinner',
            content: 'Have dinner at a local restaurant specializing in coastal cuisine.',
          },        
          {
            title: 'Day 2: Morning: Travel to Udupi',
            content: 'Check out from your hotel in Mangalore after breakfast. Travel to Udupi by road. The journey takes around 1.5 to 2 hours',
            content2: 'Check into your hotel in Udupi upon arrival.',
          },        
          {
            title: 'Afternoon: Malpe Beach',
            content: 'Head to Malpe Beach, one of the most picturesque beaches near Udupi. Participate in water sports activities such as snorkeling, banana boat rides, or kayaking.',
            content2: 'Enjoy a beachside lunch at one of the shacks serving local coastal delicacies.',
          },        
          {
            title: 'Evening: St. Mary"s Island',
            content: 'Take a boat ride from Malpe Beach to St. Marys Island famous for its unique hexagonal basalt rock formations.',
            content2: 'Spend time exploring the island, relaxing on the pristine beaches, and clicking photographs. Return to Malpe Beach by late afternoon.',
          },        
          {
            title: 'Dinner: Udupi Cuisine',
            content: 'Have dinner at a traditional Udupi restaurant, known for its vegetarian cuisine',
            content2: 'Sample authentic Udupi dishes like Masala Dosa, Goli Baje, Bisi Bele Bath, and traditional desserts like Payasam or Holige',
          },        
          {
            title: 'Day 3: Morning: Beach Activities',
            content: 'After breakfast, head back to Malpe Beach for more water sports activities or relaxation by the sea.',
            content2: 'Engage in any water sports you might have missed the previous day or simply lounge on the beach.',
          },        
          {
            title: 'Afternoon: Explore Udupi',
            content: 'Check out from your hotel in Udupi after lunch.Before departing, visit the famous Udupi Sri Krishna Temple and seek blessings.',
            content2: 'Explore the bustling local market of Udupi and shop for souvenirs or local specialties like Udupi sarees, spices, or handicrafts',
          },        
          {
            title: 'Evening: Departure',
            content: 'Depending on your departure time, you can either relax at a nearby cafe or explore any remaining attractions in Udupi',
            content2: ' Head to the airport for your onward journey, carrying back cherished memories of your beach and water sports adventure in Mangalore and Udupi',
          },        
         
        ]
      },
    },
    {
      id: 6,
      place: "india",
      imgSrc: "../images/ooty/ooty1.webp",
      altText: "Ooty",
      destination: "ooty",
      duration: "3N/4D",
      price: "86,168",
      heading: "heading1",
      details: {
        mainImg: "../../images/ooty/ooty2.jpg",
        moreImg1: "../../images/ooty/ooty3.jpg",
        moreImg2: "../../images/ooty/ooty4.jpg",
        moreImg3: "../../images/ooty/ooty5.jpg",
        moreImg4: "../../images/ooty/ooty6.jpg",
        moreImg5: "../../images/ooty/ooty7.jpg",
        days: '4',
        nights: '3',
        person: 'Inc. of all Taxes for 2 Adults , 1 child(15)',
        pricePerPerson: "86,168",
        description: [{

          describe: "Embark on a captivating Ooty Tour for 3 nights and 4 days, designed for 2 Adults and 1 Child (15). This all-inclusive package offers a seamless journey with a Delhi to Coimbatore return flight, transfers between Coimbatore and Ooty, 3-star accommodation for 3 nights, and daily breakfast.",
        },
        {
          ul: "",
          li: "Delight in the lush landscapes and charming attractions of Ooty with a suggested itinerary that includes visits to the Botanical Gardens, Ooty Lake, Doddabetta Peak, Rose Garden, Ooty Tea Factory, Nilgiri Mountain Railway, Coonoor, Sim's Park, Highfield Tea Factory, Dolphin's Nose Viewpoint, and Pykara Lake and Waterfalls."
        },
        {
          ul: "",
          li: "Enjoy local cuisine, scenic views, and cultural experiences throughout your stay. Remember, flights and hotel bookings are fully refundable before March 13th, 2024. "
        },
        {
          ul: "",
          li: "Immerse yourself in the beauty of Ooty and create lasting memories on this enchanting journey."
        },
        ],
        note: [
          'Note- Flights and hotel booking are fully refundable before 13th March 2024',
        ],
        inclusion: [
          'Delhi to Coimbatore return Flight',
          'Coimbatore to Ooty Hotel transfer',
          '3 Star accommodation in Ooty for 3 nights',
          'Hotel to Coimbatore Airport transfer',
          'Daily Breakfast',
        ],
        exclusion: ['Local transport, sightseeing, meals and anything not mentioned above.'],
        accordionItems: [
          {
            title: "Day 1 Morning: Arrival in Ooty",
            content: 'Arrive in Ooty and check into your hotel or accommodation.',
            content2: ''

          },
          {
            title: 'Afternoon: Botanical Gardens',
            content: 'Visit the Government Botanical Gardens, known for its extensive variety of plants and flowers.',
            content2: '',
          },
          {
            title: 'Evening: Ooty Lake',
            content: 'Explore Ooty Lake. You can enjoy a boat ride or a leisurely walk around the lake.',
            content2: ''
          },
          {
            title: 'Night: Dinner in Ooty',
            content: 'Try a local restaurant for dinner and enjoy the local cuisine',
            content2: '',
          },        
          {
            title: 'Day 2: Morning: Doddabetta Peak',
            content: 'Start your day by visiting Doddabetta Peak, the highest point in the Nilgiri Mountains, for breathtaking panoramic views.',
            content2: '',
          },        
          {
            title: 'Afternoon: Rose Garden and Ooty Tea Factory',
            content: 'Explore the Rose Garden, home to numerous varieties of roses. Visit the Ooty Tea Factory to learn about the tea-making process.',
            content2: '',
          },        
          {
            title: 'Evening: Nilgiri Mountain Railway',
            content: 'Take a ride on the Nilgiri Mountain Railway, a UNESCO World Heritage  Site, for a scenic journey.',
            content2: '',
          },        
          {
            title: ' Night: Dinner and Relaxation',
            content: 'Have dinner at a local restaurant and relax at your hotel.',
            content2: '',
          },        
          {
            title: 'Day 3: Morning: Coonoor Visit',
            content: 'Take a day trip to Coonoor, another charming hill station near Ooty.',
            content2: '',
          },        
          {
            title: ' Afternoon: Sim"s Park and Highfield Tea Factory',
            content: 'Explore Sim"s Park, a botanical garden known for its unique plant species. Visit the Highfield Tea Factory to understand the tea-making process',
            content2: '',
          },        
          {
            title: ' Evening: Dolphin"s Nose Viewpoint',
            content: 'Enjoy the stunning views from Dolphin"s Nose viewpoint.',
            content2: '',
          },        
          {
            title: 'Night: Return to Ooty and Dinner',
            content: 'Return to Ooty, have dinner, and relax for the night.',
            content2: '',
          },        
          {
            title: 'Day 4: Morning: Pykara Lake and Waterfalls',
            content: 'Visit Pykara Lake and Pykara Waterfalls. Enjoy the serene surroundings and optional boat rides on the lake.',
            content2: 'Departure',
          },        
         
        ]
      },
    },


  ],
  europe: [
    {
      id: 7,
      place: "europe",
      imgSrc: "../images/europe/europe1.jpg",
      altText: "europe",
      destination: "europe",
      duration: "16D/15N",
      price: "3,90,000",
      heading: "heading1",
      details: {
        mainImg: "../../images/europe/europe7.jpg",
        moreImg1: "../../images/europe/europe2.webp",
        moreImg2: "../../images/europe/europe3.webp",
        moreImg3: "../../images/europe/europe4.webp",
        moreImg4: "../../images/europe/europe5.jpg",
        moreImg5: "../../images/europe/europe6.webp",
        days: '16',
        nights: '15',
        pricePerPerson: "3,90,000",
        person: '2 Adults, 2 Kids Inc of all taxes',
        description: [{

          describe: "Experience the epitome of comfort with our meticulously crafted package, featuring Delhi to Rome return flights, ensuring a seamless start and end to your journey. Revel in the charm of carefully selected hotels and apartments, providing a perfect blend of relaxation and sophistication throughout your stay.",
        },
        {
          ul: "",
          li: "Immerse yourself in the rich tapestry of history by exploring iconic sites like the Colosseum, the Vatican City, and the Leaning Tower of Pisa."
        },
        {
          ul: "",
          li: "Uncover the cultural treasures of each city with enchanting Gondola rides in Venice, enlightening museum visits in Florence, and savoring delectable local cuisine at every turn. Our package is designed to offer not just a vacation, but a transformative experience where every moment becomes a cherished memory."
        }
        ],
        inclusion: [
          'Delhi to Rome return Flight (Starting March 5th)',
          'Hotel in Rome for 4 nights',
          'Ostia Antica site ticket',
          'Roma, Pass (3 day pass for Metro/Bus)',
          'Vatican City Museums, and sites tickets',
          'Train from Rome to Venice',
          'Hotel in VENICE for 3 nights with',
          'Hotel in Florence for 3 NIGHT',
          'Train from Florence to Pisa and return',
          'Ticket for PISA Tower and Cathedral',
          'Train from Florence to Luzern',
          'Hotel in Luzern for 3 NIGHT',
          'Tickets for Gondola rides to Mount Titlis and Glacier',
          'Train from Luzern to Engelberg',
          'Train from Engelberg to Luzern',
          'Train from Luzern to Rome',
          'Hotel/Apartment for 1 Night in Rome'
        ],
        exclusion: [''],

        tableData: [
          ['14th June', 'Saturday', 'Rome, Italy ', '20/29 ', 'Delhi to Rome', 'Delhi: 3:00 AM, Arrival at Rome at 8:00 AM, check into hotel, Relax and stroll around Rome, ', 'Saaz Restobar, Jaipur, Himalaya Kashmir, Gandhi 2 Restaurant'],
          ['15th June', 'Sunday', 'Rome, Italy ', '20/29 ', 'Colloseum, Roman Forum', 'Add Paletine Hill if you have spare time, Arrival at Rome at 8:00 AM, check into hotel, Relax and stroll around Rome, ', 'Saaz Restobar, Jaipur, Himalaya Kashmir, Gandhi 2 Restaurant'],
          ['16th June', 'Monday', 'Rome, Italy ', '20/29 ', 'Vatican City Tour', 'Vatican Museums, Sistine Chapel, and St. Peter s Basilica. Trevi Fountain if time permits in Rom', 'Saaz Restobar, Jaipur, Himalaya Kashmir, Gandhi 2 Restaurant'],
          ['17th June', 'Tuesday', 'Rome, Italy ', '20/29 ', 'Day tour of Ostia Antica', 'Take the metro (line B) and get off at Piramide. Here, take the Roma Lido commuter train to Ostia Antica. Exploration of Ruins, The Harbor, and Mithraeum ', 'Saaz Restobar, Jaipur, Himalaya Kashmir, Gandhi 2 Restaurant'],
          ['18th June', 'Wednesday', 'Venice,Italy', '16/25  ', 'Rome to Venice Train in morning(4Hours)', 'St. Marks Square (Piazza San Marco), St. Mark s Basilica,', 'Ital-India Restaurant. Brothers Fast Food Namaste Indian Restaurant.Buddha Soul Restò Bombay  Spice'],
          ['19th June', 'Thursday', 'Venice,Italy', '16/25  ', 'Gondola Ride and Palazzo Ducale', 'Add Gallerie dell Accademia:', 'Ital-India Restaurant. Brothers Fast Food Namaste Indian Restaurant.Buddha Soul RestòBombay Spice'],
          ['20th June', 'Friday', 'Venice,Italy', '16/25  ', 'Visit to Murano and Burano island', 'Glassblowing Demonstration, Murano Glass Museum, Lace-Making Tradition of Burano, Church of San Martino', 'Ital-India Restaurant. Brothers Fast Food Namaste Indian Restaurant.Buddha Soul Restò Bombay Spice'],
          ['21th June', 'Saturday', 'Florenze, Italy', '14/26  ', 'Venice to Florence Train in Morning(2 Hours)', 'You can visit Florence Cathedral (Duomo).Stroll through the Piazza dellaSignoria and visit the Palazzo Vecchio, admiring the outdoor sculpture gallery', 'Haveli, Royal India, Gandhi Florence Al Noor Indian Restaurant'],
          ['22th June', 'Sunday', 'Venice,Italy', '14/26  ', 'Day tour to PISA by train and return', 'Pisa Cathedral (Duomo di Pisa), Leaning Tower of Pisa, Field of Miracles (Piazza dei Miracoli)', 'Haveli, Royal India, Gandhi Florence Al Noor Indian Restaurant'],
          ['23th June', 'Monday', 'Florenze,Italy', '14/26  ', 'Pitti Palace and Boboli Gardens. Basilica of Santa Croce', 'Climb up to Piazzale Michelangelo for panoramic views of Florence during sunset.', 'Haveli, Royal India, Gandhi Florence Al Noor Indian Restaurant'],
          ['24th June', 'Tuesday', 'Zurich, Switzerland', '13/23  ', 'Florence to Zurich Train in Morning (8 Hours) ', 'Anuj Home', ''],
          ['25th June', 'Wednesday', 'Luzern,Switzerland', '13/23  ', 'Zurich to Luzern Train in Afternoon (41 Min)', 'Chapel Bridge, Lion Monument', 'GourmIndia.Spices Kitchen & TerraceIndian Restaurant LuzernKanchi Indian Restaurant, Doa house'],
          ['26th June', 'Thursday', 'Luzern,Switzerland', '13/23  ', 'Luzern to Engelberg and Return by train (1 hour)', 'Mount Titlis-Temp(-2/8)', 'GourmIndia.Spices Kitchen & TerraceIndian Restaurant LuzernKanchi Indian Restaurant, Doa house'],
          ['27th June', 'Friday', 'Luzern,Switzerland', '13/23  ', 'Boat Ride on Lake Lucerne', 'Mount Pilatis-Temp(1/10)', 'GourmIndia.Spices Kitchen & TerraceIndian Restaurant LuzernKanchi Indian Restaurant, Doa house'],
          ['28th June', 'Saturday', 'Rome,Italy', '13/23  ', 'Luzern to Rome ', 'Arrive in the Afternoon, Check', ''],
          // Repeat this pattern for the remaining rows
        ],
      },
    },
  ],
  // lakshdweep: [
  //   {
  //     id: 1,
  //     place: "lakshdweep",
  //     imgSrc: "../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
  //     altText: "lakshdweep",
  //     destination: "lakshdweep",
  //     duration: "2D/3N",
  //     price: "1,15,000",
  //     heading: "heading1",
  //     details: {
  //       mainImg: "../../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
  //       moreImg1: "../../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
  //       moreImg2: "../../images/lakshdweep/lakshadweeptourism.webp",
  //       moreImg3: "../../images/lakshdweep/lakshdweep.webp",
  //       moreImg4: "../../images/lakshdweep/beach-resort-Bangaram-islandIndiaLakshadweep.webp",
  //       moreImg5: "../../images/lakshdweep/outlooktraveller.webp",
  //       days: '5',
  //       nights: '4',
  //       person: '2 Adults',
  //       pricePerPerson: "1,15,000",
  //       description: [{

  //         describe: "Offering a 4-night/5-day package to Lakshadweep for 2 adults. The package includes return flights from Delhi to Kochi and from Kochi to Lakshadweep, a 1-night stay in a 3-star hotel in Kochi with breakfast, AC deluxe rooms in Agatti Island for 3 nights, welcome drink at the resort, and breakfast, lunch, and dinner on Agatti Island. ",
  //       },
  //       {
  //         ul: "",
  //         li: "The package also includes morning and evening tea with snacks, sightseeing in Agatti Village, a visit to Thinnakara Island by boat, a shipwreck visit, a visit to the sandbank near Bangaram, a glass boat ride, a visit to Kalpitti Island, and airport transfers at Agatti Island.ding"
  //       },
  //       {
  //         ul: "",
  //         li: "Water sports and any additional sightseeing are not included in the package. The package price is INR 1,15,000 inclusive of all taxes for 2 adults"
  //       },
  //       ],
  //       inclusion: [
  //         'Delhi to Kochi to Lakshadweep Return flight',
  //         '3 star Hotel in Kochi for 1 Night with Breakfast',
  //         'Permit Cost for Lakshadweep',
  //         'AC Deluxe rooms in Agatti Island for 3N Four days',
  //         'Welcome Drink at the resort',
  //         'Breakfast, Lunch, and Dinner on Agatti Island',
  //         'Morning and Evening Tea with Snacks',
  //         'Agatti Village Sightseeing ',
  //         'Thinnakara Island visit by boat',
  //         'Shipwreck visit',
  //         'Sandbank near Bangaram, Glass Boat ride',
  //         'Kalpitti Island visit',
  //         'Airport transfer at Agatti Island'
  //       ],
  //       exclusion: ['Water sports and any sightseeing not mentioned in the itinerary', 'Personal expenses'],
  //       accordionItems: [
  //         {
  //           title: 'Day 1 ',
  //           content: 'Morning: Take a flight from Delhi to Kochi. Afternoon: Arrive in Kochi and spend the day exploring Fort Kochi, visiting the Chinese',
  //           content2: 'Fishing Nets, St. Francis Church, and Mattancherry Palace. Evening: Enjoy a local dinner and relax in Kochi'
  //           ,
  //         },
  //         {
  //           title: 'Day 2',
  //           content: 'On arrival at Agatti airport, our representative will receive and escort you to the Hotel. After lunch take rest for 3 hours at the hotel and by 5 o clock proceed for Agatti Island sightseeing',
  //           content2: 'which includes – South Beach, Eastern jetty (ship embarkation and disembarkation point), NIOT sea water desalination Plant, Lagoon beach a sunset point, relaxation in the creamy sand for a couple of hours and back to Hotel around 7:00 pm, dinner will be served at 8:30 pm.- Overnight stay at Agatti',
  //         },
  //         {
  //           title: 'Day 3',
  //           content: 'Early morning after breakfast around 9 am proceed to Thinnakara Island, beautiful uninhabited islands, Tinnakara islands, in a single atoll, lying in the northern side of Agatti. Tour by private boat from Agatti 45 minutes Journey. The trip provides beautiful sandy beaches and swimming with turtles, snorkeling in blue shallow water.',
  //           content2: 'After that boat will move to shipwreck snorkeling and then to Sand banks near Bangaram Island. After 5:30 pm the boat moves to Agatti Hotel, relaxes in the room, and dinner will be served at 8:30 pm. Please note movement of the boat to Thinnakara depends on high tide and low tide of the sea level. Overnight stay at Agatti.',
  //         },
  //         {
  //           title: 'Day 4',
  //           content: 'After the Morning breakfast around 9 AM enjoy the Glass bottom boating in the lagoon for 1 hour. And Enjoy the water sports activities like the Banana ride. Kayak, Snorkelling, and other water sports activities are available on extra payment. Lunch will be served from 12:30 to 2.00 PM.',
  //           content2: 'After 4:30 pm proceed to Kalpitti Island or Turtle Watch, a small islet on the southern tip of Agatti, which offers swimming and turtle watch, The journey by a small boat in shallow water,sightseeing in the island by walk, after sightseeing Kalpitti Island return to Agatti around 6:30 pm. overnight stay at Agatti.'
  //         },
  //         {
  //           title: 'Day 5',
  //           content: 'This is the final day at Agatti, Morning after breakfast proceeds to finish departure formalities and proceed to the Airport,',
  //           content2: 'for a return journey to Kochi by Air and from Kochi to Delhi by Air.',
  //         },
  //       ]
  //     },
  //   },


  // ],
  // kerala: [
  //   {
  //     id: 1,
  //     place: "kerala",
  //     imgSrc: "../images/uttrakhand/mussorie.png",
  //     altText: "kerala",
  //     destination: "kerala",
  //     duration: "5D/4N",
  //     price: "30,900",
  //     heading: "heading1",
  //     details: {
  //       mainImg: "../../images/uttrakhand/mussorie.png",
  //       moreImg1: "../../images/uttrakhand/mussorie.png",
  //       moreImg2: "../../images/uttrakhand/mussorie.png",
  //       moreImg3: "../../images/uttrakhand/mussorie.png",
  //       moreImg4: "../../images/uttrakhand/mussorie.png",
  //       moreImg5: "../../images/uttrakhand/mussorie.png",
  //       days: '5',
  //       nights: '4',
  //       person: 'person (Twin Sharing Basis)',
  //       pricePerPerson: "30,900",
  //       description: [{

  //         describe: "Experience the tranquil beauty of Kerala with our Kerala Tour package, carefully crafted to immerse you in the rich culture and natural wonders of this southern paradise. Begin your journey with a flight to Kochi, where you'll be welcomed by the warm hospitality of Kerala. ",
  //       },
  //       {
  //         ul: "",
  //         li: "Glide along the backwaters of Alleppey on a traditional houseboat, savoring delicious local cuisine amidst serene surroundings."
  //       },
  //       {
  //         ul: "",
  //         li: "Then, journey to Munnar to explore misty tea plantations and cascading waterfalls. With comfortable accommodations and expertly planned itineraries, let us guide you through an unforgettable adventure in Kerala."
  //       },
  //       ],
  //       inclusion: [
  //         'Delhi to Kochi Return Flight',
  //         'Airport to Alleppey Houseboat',
  //         'Alleppey Houseboat cruise and night stay with Breakfast, Lunch and Dinner',
  //         'Alleppey to Munnar Transfer by Taxi',
  //         'Hotel Accommodation in Munnar for 3 Nights with breakfast',
  //         'Munnar to Kochi Airport by Taxi'

  //       ],
  //       exclusion: ['Local transport, sightseeing, meals, and anything not mentioned above. '],
  //       accordionItems: [
  //         {
  //           title: 'Day 1: Arrival in Cochin and Transfer to Alleppey',
  //           content: 'Arrive in Cochin (Kochi) and transfer to Alleppey. ',
  //           content2: 'Check-in to your houseboat for an overnight stay.• Explore the backwaters of Alleppey, enjoy the serene environment, and savor a traditional Kerala dinner on the houseboat.'
  //           ,
  //         },
  //         {
  //           title: 'Day 2: Alleppey to Munnar',
  //           content: 'Check out from the houseboat after breakfast and proceed to Munnar (approx. 4-5 hours drive).',
  //           content2: 'Arrive in Munnar, check in to your hotel, and rest for a while Evening at leisure. You can explore the local markets in Munnar',
  //         },
  //         {
  //           title: 'Day 3: Munnar Sightseeing',
  //           content: 'Visit the Mattupetty Dam and Echo Point for scenic views. Explore the Tea Gardens and learn about the tea-making process.',
  //           content2: 'Visit the Rose Garden and Hydel Park. Optional: Visit the Attukal Waterfalls.',
  //         },
  //         {
  //           title: 'Day 4: Munnar Sightseeing',
  //           content: 'Explore Eravikulam National Park (home to the Nilgiri Tahr). Visit the Anamudi Peak, the highest point in South India.',
  //           content2: ' Visit the Tea Museum to learn more about tea processing. Relax in the evening and explore local cuisine',
  //         },
  //         {
  //           title: 'Day 5: Departure',
  //           content: 'Check-out from the hotel and proceed to Cochin for departure. Optional: Visit Fort Kochi if time permits.',
  //           content2: ' Depart from Cochin with fond memories of your Alleppey and Munnar trip',
  //         },
  //       ]
  //     },
  //   },


  // ],
  // andaman: [
  //   {
  //     id: 1,
  //     place: "andaman",
  //     imgSrc: "../images/andaman/andaman1.jpg",
  //     altText: "andaman",
  //     destination: "andaman",
  //     duration: "7D/6N",
  //     price: "87,700",
  //     heading: "heading1",
  //     details: {
  //       mainImg: "../../images/andaman/andaman1.jpg",
  //       moreImg1: "../../images/andaman/andaman2.webp",
  //       moreImg2: "../../images/andaman/andaman3.jpg",
  //       moreImg3: "../../images/andaman/andaman4.webp",
  //       moreImg4: "../../images/andaman/andaman5.jpg",
  //       moreImg5: "../../images/andaman/andaman6.jpg",
  //       days: '6',
  //       nights: '7',
  //       person: '2 Adults',
  //       pricePerPerson: "87,700",
  //       description: [{

  //         describe: "The Andaman Tour-6N/7Days package offers a delightful 7-day adventure for 2 adults, encompassing return flights from Delhi to Port Blair, comfortable 3-star accommodations in Port Blair, Havelock Island, and Neil Island, ferry transfers between the islands, complimentary breakfast, and airport transfers in Delhi and Port Blair.",
  //       },
  //       {
  //         ul: "",
  //         li: "The itinerary includes visits to picturesque beaches, historical landmarks, museums, and opportunities for water activities such as snorkeling, scuba diving, and glass-bottom boat rides."
  //       },
  //       {
  //         ul: "",
  //         li: "With a package price of INR 87,700 inclusive of all taxes, this well-rounded tour provides a perfect blend of relaxation, exploration, and cultural immersion in the captivating Andaman Islands."
  //       },
  //       ],
  //       inclusion: [
  //         'Return Flight from Delhi to Port Blair',
  //         '3 Star Hotel for 2N in Port Blair',
  //         'Ferry from Port Blair to Havelock (Swaraj) Island',
  //         '3 Stat Hotel for 2N in Havelock Island',
  //         'Ferry from Havelock to Neil (Shaheed Island)',
  //         '3 Star Hotel for 1 Night in Neil Island ',
  //         'Ferry from Neil Island to Port Blair ',
  //         'Hotel for 1N in Port Blair',
  //         'Free Breakfast',
  //         'Airport Transfer at Delhi and Portblair'

  //       ],
  //       exclusion: ['Local transport, sightseeing and anything not mentioned above. '],
  //       accordionItems: [
  //         {
  //           title: 'Day 1: Arrival in Port Blair',
  //           content: 'Fly from Delhi to Veer Savarkar International Airport in Port Blair. Check in at the hotel/resort and freshen up. Visit Corbyns Cove Beach for some relaxation and beach time.',
  //           content2: 'Witness the mesmerizing Sound and Light Show at the Cellular Jail in the evening. Dinner at a local restaurant and overnight stay in Port Blair.'

  //         },
  //         {
  //           title: 'Day 2: Explore Port Blair',
  //           content: 'Early breakfast and head to the Marina Park and Aquarium.Explore the Anthropological Museum to learn about the indigenous tribes of the islands.',
  //           content2: ' Visit Chatham Saw Mill, one of the oldest and largest sawmills in Asia.Afternoon visit to Samudrika Naval Marine Museum.Return to the hotel, dinner, and overnight stay in Port Blair',
  //         },
  //         {
  //           title: 'Day 3: Port Blair - Havelock Island',
  //           content: 'Check out from the hotel after breakfast and proceed to the Havelock Island by a private ferry. Check-in at the resort/hotel upon arrival.',
  //           content2: ' Relax and explore the beautiful Radhanagar Beach (Beach No. 7), renowned for its crystal-clear waters and stunning sunset views. Dinner and overnight stay in Havelock Island.',
  //         },
  //         {
  //           title: 'Day 4: Havelock Island - Elephant Beach',
  //           content: 'After breakfast, head to Elephant Beach. Enjoy water activities like snorkeling, scuba diving, or glass-bottom boat rides in the clear waters. Return to the hotel in the afternoon. ',
  //           content2: ' Leisure time to explore or relax on your own. Dinner and overnight stay in Havelock Island',
  //         },
  //         {
  //           title: 'Day 5 : Havelock Island - Neil Island',
  //           content: 'Check out from the hotel after breakfast and take a ferry to Neil Island. Check-in at the hotel and relax for a while. ',
  //           content2: 'Visit the stunning beaches of Neil Island: Bharatpur Beach, Sitapur Beach, and Laxmanpur Beach. Witness the natural rock formations and beautiful sunset at Laxmanpur Beach. Dinner and overnight stay in Neil Island.',
  //         },
  //         {
  //           title: 'Day 6 : Neil Island - Port Blair',
  //           content: 'Enjoy breakfast and explore more of Neil Island if time permits. Check-out from the hotel and return to Port Blair via ferry. ',
  //           content2: 'Check in at the hotel and relax for the rest of the day. Shopping at Aberdeen Bazaar for souvenirs and local handicrafts. Farewell dinner and overnight stay in Port Blair',
  //         },
  //         {
  //           title: 'Day 7: Departure from Port Blair',
  //           content: 'De on the flight timing, spend the morning at leisure or visit any missed attractions. ',
  //           content2: 'Check-out from the hotel and transfer to the airport for your flight back to Delhi r',
  //         },
  //       ]
  //     },
  //   },


  // ],
  // rajasthan: [
  //   {
  //     id: 1,
  //     place: "rajasthan",
  //     imgSrc: "../images/rajasthan/udaipurLakePichola.webp",
  //     altText: "Rajasthan ",
  //     destination: "rajasthan",
  //     duration: "7N/8D",
  //     price: "94,230",
  //     heading: "heading1",
  //     details: {
  //       mainImg: "../../images/rajasthan/jaisalmerBadaBagh.webp",
  //       moreImg1: "../../images/rajasthan/jaisalmerGadisarLake.webp",
  //       moreImg2: "../../images/rajasthan/mountAbuDilwaraJainTemples.webp",
  //       moreImg3: "../../images/rajasthan/udaipurCityPalace.webp",
  //       moreImg4: "../../images/rajasthan/udaipurSaheliyonKiBari.webp",
  //       moreImg5: "../../images/rajasthan/mountAbuNakkiLake.webp",
  //       days: '6',
  //       nights: '7',
  //       person: '4 Adults Inc. of all taxes',
  //       pricePerPerson: "94,230",
  //       description: [{

  //         describe: "Embark on a captivating 7-night, 8-day Rajasthan Tour, encompassing the enchanting cities of Udaipur, Mount Abu, and Jaisalmer. This meticulously crafted itinerary offers a perfect blend of historical exploration, architectural marvels, and natural splendor.",
  //       },
  //       {
  //         ul: "",
  //         li: "From the intricate marble architecture of the Ranakpur Jain Temples to the exquisite carvings at the Dilwara Jain Temples, and the golden hues of Jaisalmer Fort, each day promises a new adventure."
  //       },
  //       {
  //         ul: "",
  //         li: " Enjoy boat rides on picturesque lakes, witness mesmerizing sunsets, and delve into the vibrant local markets. With flights, comfortable hotel stays, and breakfast included, this all-inclusive package ensures a seamless and unforgettable journey through the heart of Rajasthan."
  //       },
  //       ],
  //       inclusion: [
  //         'Flights from Delhi to Udaipur and from Jaisalmer to Delhi',
  //         'Taxi transfers from Udaipur to Mount Abu and from Mount Abu to Jaisalmer',
  //         '3-star hotel accommodations in Udaipur, Mount Abu, and Jaisalmer',
  //         'Complimentary breakfast during the stay',
  //         'Airport transfers for added convenience',
  //       ],
  //       exclusion: ['Local transport expenses', 'Sightseeing costs', 'Any other expenses not specified in the inclusions'],
  //       accordionItems: [
  //         // {mainTitle:"Day 1-3: Udaipur"},
  //         {
  //           title: "Day 1",
  //           content: '- Arrival in Udaipur and hotel check-in',
  //           content2: '- Visit to the City Palace to explore its stunning architecture and enjoy scenic views of Lake Pichola Evening boat ride on Lake Pichola to witness the beautiful sunset'

  //         },
  //         {
  //           title: 'Day 2',
  //           content: '-Visit to Saheliyon Ki Bari, a serene garden with lush greenery, fountains, and marble art Exploration of Jagdish Temple, an ancient Hindu temple dedicated to Lord Vishnu',
  //           content2: '- Time for wandering through the local markets and shopping for traditional Rajasthani crafts                          ',
  //         },
  //         {
  //           title: 'Day 3 ',
  //           content: '- Take a day trip to Kumbhalgarh Fort, known for its massive walls and historical significance',
  //           content2: '- Visit the intricate Ranakpur Jain Temples, featuring stunning marble architecture',
  //         },
  //         {
  //           title: 'Day 4',
  //           content: 'Travel to Mount Abu (approx. 3-4 hours by road)',
  //           content2: 'Check into your hotel and relax for the evening.',
  //         },
  //         {
  //           title: 'Day 5',
  //           content: '- Visit to Dilwara Jain Temples, renowned for their exquisite marble carvings',
  //           content2: '- Exploration of Nakki Lake and the opportunity to enjoy a boat ride (if available). Visit to the sunset point to witness a mesmerizing sunset view',
  //         },
  //         {
  //           title: 'Day 6',
  //           content: '- Travel to Jaisalmer (approx. 9 hours by road)',
  //           content2: 'Check into your hotel and relax.',
  //         },
  //         {
  //           title: 'Day 7',
  //           content: '- Exploration of Jaisalmer Fort, known for its golden hue and historical importance. Visit to Patwon Ki Haveli and Nathmal Ki Haveli to admire the Rajasthani architecture',
  //           content2: '- Enjoy a camel safari in the Sam Sand Dunes during the evening',
  //         },
  //         {
  //           title: 'Day 8',
  //           content: '- Visit to Bada Bagh, a garden complex with royal cenotaphs. Exploration of Gadisar Lake and its surrounding temples and ghats',
  //           content2: '- Departure from Jaisalmer for your onward journey ',
  //         },
  //       ]
  //     },
  //   },


  // ],
  kazakhstan: [
    {
      id: 8,
      place: "kazakhstan",
      imgSrc: "../images/kazakhstan/kazakstan1.jpg",
      altText: "kazakhstan",
      destination: "kazakhstan",
      duration: "3N/4D Double Sharing Basis",
      price: "18000",
      heading: "heading1",
      details: {
        mainImg: "../../images/kazakhstan/kazakstan7.jpg",
        moreImg1: "../../images/kazakhstan/kazakstan2.jpg",
        moreImg2: "../../images/kazakhstan/kazakstan3.jpg",
        moreImg3: "../../images/kazakhstan/kazakstan4.jpg",
        moreImg4: "../../images/kazakhstan/kazakstan5.jpg",
        moreImg5: "../../images/kazakhstan/kazakstan6.jpg",
        days: '4',
        nights: '3',
        person: ' + GST per person',
        pricePerPerson: "18000",
        description: [{

          describe: "Almaty is the major commercial, financial, and cultural center of kazakhstan, as well as its most populous and cosmopolitan city. The city is located in the mountainous area of southern kazakhstan near the border with Kyrgyzstan in the foothills of the Trans-Ili Alatau at an elevation of 700–900 m (2,300–3,000 feet), where the Large and Small Almatinka rivers run into the plain.",
        },
        {
          ul: "Tourist Attraction",
          li: "The Medeo Sports Complex First President's Park Zenkov Cathedral (Ascension Cathedral) Kok Tobe Park, TV Tower, and Beatles Monument Museum of Folk Musical Instruments named after Ykhlas Big Almaty Lake Green Bazaar"
        },
        {
          ul: "Temperature",
          li: "The temperature in Almaty in February is between 2 degrees/-8 degrees. Will have the opportunity to enjoy snow/snowfall."
        },
        {
          ul: "kazakhstan Currency",
          li: "kazakhstan Currency is kazakhstani Tenge 1 Rupee=5.45 Tenge"
        },
        ],
        inclusion: [
          'Return economy Flight (Only Cabin Baggage)',
          '3 Star Hotel',
          'Free Visa',
          'Breakfast',
        ],
        exclusion: ['Local transport, sightseeing and anything not mentioned above.'],
        accordionItems: [
          // {mainTitle:"Day 1-3: Udaipur"},

        ]
      },
    },


  ],
  // manglore: [
  //   {
  //     id: 1,
  //     place: "manglore",
  //     imgSrc: "../images/manglore/manglore.png",
  //     altText: "Manglore",
  //     destination: "manglore",
  //     duration: "3N/4D",
  //     price: " 69,474",
  //     heading: "heading1",
  //     details: {
  //       mainImg: "../../images/manglore/manglore.jpeg",
  //       moreImg1: "../../images/manglore/manglore1.jpg",
  //       moreImg2: "../../images/manglore/manglore2.jpeg",
  //       moreImg3: "../../images/manglore/manglore3.jpeg",
  //       moreImg4: "../../images/manglore/manglore4.jpeg",
  //       moreImg5: "../../images/manglore/manglore5.jpeg",
  //       days: '4',
  //       nights: '3',
  //       person: 'Inc. of all Taxes for 2 Adults, 1 Child(15)',
  //       pricePerPerson: "69,474",
  //       description: [{

  //         describe: "Embark on a captivating journey through Mangalore and Udupi during your Ooty Tour-3N/4Days. Begin your adventure in Mangalore, where you'll visit the vibrant Panambur Beach, known for its water sports and stunning sunset views. Explore the nearby Tannirbhavi Beach and admire the exquisite frescoes at the St. Aloysius Chapel, immersing yourself in the local culture and beauty.",
  //       },
  //       {
  //         ul: "",
  //         li: "In Udupi, experience the picturesque Malpe Beach, offering a range of water sports activities and delectable coastal cuisine at beachside shacks. Venture to St. Mary's Island via a scenic boat ride to marvel at the unique hexagonal basalt rock formations and relax on the pristine beaches. "
  //       },
  //       {
  //         ul: "",
  //         li: " Visit the renowned Udupi Sri Krishna Temple to seek blessings and wander through the bustling local market to shop for souvenirs and local specialties.Indulge in authentic Udupi cuisine, including mouthwatering dishes like Masala Dosa, Goli Baje, and traditional desserts like Payasam."
  //       },
  //       {
  //         ul: "",
  //         li: " Whether you're engaging in water sports, exploring cultural landmarks, or savoring local flavors, your journey through Mangalore and Udupi promises unforgettable memories and enriching experiences."
  //       },
  //       ],
  //       inclusion: [
  //         'Delhi to Mangalore Flight',
  //         'Mangalore to Delhi flight(1 Stop Bangalore)',
  //         'Apartment stay in Mangalore for 1 Night',
  //         'Apartment stay in Udupi for 1 Night',
  //         'Mangalore to Udupi by Taxi',
  //         'Udupi to Mangalore Airport by Taxi',
  //       ],
  //       exclusion: ['Local transport, sightseeing, meals and anything not mentioned above.'],
  //       accordionItems: [
  //         {
  //           title: "Day-1 Morning: Arrival in Mangalore",
  //           content: 'Arrive in Mangalore',
  //           content2: 'Check into your hotel and freshen up'

  //         },
  //         {
  //           title: 'Afternoon: Panambur Beach',
  //           content: 'Head to Panambur Beach, one of the most popular beaches in Mangalore',
  //           content2: 'Engage in water sports activities like jet skiing, banana boat rides, parasailing, or surfing (depending on availability and weather conditions). Enjoy a delicious beachside lunch at one of the shacks.',
  //         },
  //         {
  //           title: 'Evening: Relaxation',
  //           content: 'Spend the evening relaxing at the beach, soaking in the sunset views.',
  //           content2: ' You can also explore nearby attractions like the Tannirbhavi Beach or visit the St. Aloysius Chapel to admire its beautiful frescoes',
  //         },
  //         {
  //           title: 'Dinner',
  //           content: 'Have dinner at a local restaurant specializing in coastal cuisine.',
  //         },        
  //         {
  //           title: 'Day 2: Morning: Travel to Udupi',
  //           content: 'Check out from your hotel in Mangalore after breakfast. Travel to Udupi by road. The journey takes around 1.5 to 2 hours',
  //           content2: 'Check into your hotel in Udupi upon arrival.',
  //         },        
  //         {
  //           title: 'Afternoon: Malpe Beach',
  //           content: 'Head to Malpe Beach, one of the most picturesque beaches near Udupi. Participate in water sports activities such as snorkeling, banana boat rides, or kayaking.',
  //           content2: 'Enjoy a beachside lunch at one of the shacks serving local coastal delicacies.',
  //         },        
  //         {
  //           title: 'Evening: St. Mary"s Island',
  //           content: 'Take a boat ride from Malpe Beach to St. Marys Island famous for its unique hexagonal basalt rock formations.',
  //           content2: 'Spend time exploring the island, relaxing on the pristine beaches, and clicking photographs. Return to Malpe Beach by late afternoon.',
  //         },        
  //         {
  //           title: 'Dinner: Udupi Cuisine',
  //           content: 'Have dinner at a traditional Udupi restaurant, known for its vegetarian cuisine',
  //           content2: 'Sample authentic Udupi dishes like Masala Dosa, Goli Baje, Bisi Bele Bath, and traditional desserts like Payasam or Holige',
  //         },        
  //         {
  //           title: 'Day 3: Morning: Beach Activities',
  //           content: 'After breakfast, head back to Malpe Beach for more water sports activities or relaxation by the sea.',
  //           content2: 'Engage in any water sports you might have missed the previous day or simply lounge on the beach.',
  //         },        
  //         {
  //           title: 'Afternoon: Explore Udupi',
  //           content: 'Check out from your hotel in Udupi after lunch.Before departing, visit the famous Udupi Sri Krishna Temple and seek blessings.',
  //           content2: 'Explore the bustling local market of Udupi and shop for souvenirs or local specialties like Udupi sarees, spices, or handicrafts',
  //         },        
  //         {
  //           title: 'Evening: Departure',
  //           content: 'Depending on your departure time, you can either relax at a nearby cafe or explore any remaining attractions in Udupi',
  //           content2: ' Head to the airport for your onward journey, carrying back cherished memories of your beach and water sports adventure in Mangalore and Udupi',
  //         },        
         
  //       ]
  //     },
  //   },


  // ],
  // ooty: [
  //   {
  //     id: 1,
  //     place: "ooty",
  //     imgSrc: "../images/ooty/ooty1.webp",
  //     altText: "Ooty",
  //     destination: "ooty",
  //     duration: "3N/4D",
  //     price: "86,168",
  //     heading: "heading1",
  //     details: {
  //       mainImg: "../../images/ooty/ooty2.jpg",
  //       moreImg1: "../../images/ooty/ooty3.jpg",
  //       moreImg2: "../../images/ooty/ooty4.jpg",
  //       moreImg3: "../../images/ooty/ooty5.jpg",
  //       moreImg4: "../../images/ooty/ooty6.jpg",
  //       moreImg5: "../../images/ooty/ooty7.jpg",
  //       days: '4',
  //       nights: '3',
  //       person: 'Inc. of all Taxes for 2 Adults , 1 child(15)',
  //       pricePerPerson: "86,168",
  //       description: [{

  //         describe: "Embark on a captivating Ooty Tour for 3 nights and 4 days, designed for 2 Adults and 1 Child (15). This all-inclusive package offers a seamless journey with a Delhi to Coimbatore return flight, transfers between Coimbatore and Ooty, 3-star accommodation for 3 nights, and daily breakfast.",
  //       },
  //       {
  //         ul: "",
  //         li: "Delight in the lush landscapes and charming attractions of Ooty with a suggested itinerary that includes visits to the Botanical Gardens, Ooty Lake, Doddabetta Peak, Rose Garden, Ooty Tea Factory, Nilgiri Mountain Railway, Coonoor, Sim's Park, Highfield Tea Factory, Dolphin's Nose Viewpoint, and Pykara Lake and Waterfalls."
  //       },
  //       {
  //         ul: "",
  //         li: "Enjoy local cuisine, scenic views, and cultural experiences throughout your stay. Remember, flights and hotel bookings are fully refundable before March 13th, 2024. "
  //       },
  //       {
  //         ul: "",
  //         li: "Immerse yourself in the beauty of Ooty and create lasting memories on this enchanting journey."
  //       },
  //       ],
  //       note: [
  //         'Note- Flights and hotel booking are fully refundable before 13th March 2024',
  //       ],
  //       inclusion: [
  //         'Delhi to Coimbatore return Flight',
  //         'Coimbatore to Ooty Hotel transfer',
  //         '3 Star accommodation in Ooty for 3 nights',
  //         'Hotel to Coimbatore Airport transfer',
  //         'Daily Breakfast',
  //       ],
  //       exclusion: ['Local transport, sightseeing, meals and anything not mentioned above.'],
  //       accordionItems: [
  //         {
  //           title: "Day 1 Morning: Arrival in Ooty",
  //           content: 'Arrive in Ooty and check into your hotel or accommodation.',
  //           content2: ''

  //         },
  //         {
  //           title: 'Afternoon: Botanical Gardens',
  //           content: 'Visit the Government Botanical Gardens, known for its extensive variety of plants and flowers.',
  //           content2: '',
  //         },
  //         {
  //           title: 'Evening: Ooty Lake',
  //           content: 'Explore Ooty Lake. You can enjoy a boat ride or a leisurely walk around the lake.',
  //           content2: ''
  //         },
  //         {
  //           title: 'Night: Dinner in Ooty',
  //           content: 'Try a local restaurant for dinner and enjoy the local cuisine',
  //           content2: '',
  //         },        
  //         {
  //           title: 'Day 2: Morning: Doddabetta Peak',
  //           content: 'Start your day by visiting Doddabetta Peak, the highest point in the Nilgiri Mountains, for breathtaking panoramic views.',
  //           content2: '',
  //         },        
  //         {
  //           title: 'Afternoon: Rose Garden and Ooty Tea Factory',
  //           content: 'Explore the Rose Garden, home to numerous varieties of roses. Visit the Ooty Tea Factory to learn about the tea-making process.',
  //           content2: '',
  //         },        
  //         {
  //           title: 'Evening: Nilgiri Mountain Railway',
  //           content: 'Take a ride on the Nilgiri Mountain Railway, a UNESCO World Heritage  Site, for a scenic journey.',
  //           content2: '',
  //         },        
  //         {
  //           title: ' Night: Dinner and Relaxation',
  //           content: 'Have dinner at a local restaurant and relax at your hotel.',
  //           content2: '',
  //         },        
  //         {
  //           title: 'Day 3: Morning: Coonoor Visit',
  //           content: 'Take a day trip to Coonoor, another charming hill station near Ooty.',
  //           content2: '',
  //         },        
  //         {
  //           title: ' Afternoon: Sim"s Park and Highfield Tea Factory',
  //           content: 'Explore Sim"s Park, a botanical garden known for its unique plant species. Visit the Highfield Tea Factory to understand the tea-making process',
  //           content2: '',
  //         },        
  //         {
  //           title: ' Evening: Dolphin"s Nose Viewpoint',
  //           content: 'Enjoy the stunning views from Dolphin"s Nose viewpoint.',
  //           content2: '',
  //         },        
  //         {
  //           title: 'Night: Return to Ooty and Dinner',
  //           content: 'Return to Ooty, have dinner, and relax for the night.',
  //           content2: '',
  //         },        
  //         {
  //           title: 'Day 4: Morning: Pykara Lake and Waterfalls',
  //           content: 'Visit Pykara Lake and Pykara Waterfalls. Enjoy the serene surroundings and optional boat rides on the lake.',
  //           content2: 'Departure',
  //         },        
         
  //       ]
  //     },
  //   },


  // ],
  thailand: [
    {
      id: 9,
      place: "thailand",
      imgSrc: "../images/thailand/thailand1.jpg",
      altText: "thailand",
      destination: "thailand",
      duration: "4N/5D",
      price: "1,43,500",
      heading: "heading1",
      details: {
        mainImg: "../../images//thailand/thailand7.webp",
        moreImg1: "../../images//thailand/thailand2.webp",
        moreImg2: "../../images//thailand/thailand3.jpg",
        moreImg3: "../../images//thailand/thailand4.jpg",
        moreImg4: "../../images//thailand/thailand5.webp",
        moreImg5: "../../images//thailand/thailand6.webp",
        days: '5',
        nights: '4',
        person: 'Inc. of all costs for 2 adults and 1 kid',
        pricePerPerson: "1,43,500",
        description: [{

          describe: "",
        },
        {
          ul: "",
          li: ""
        },
        {
          ul: "",
          li: ""
        },
        {
          ul: "",
          li: ""
        },
        ],
        note: [
          'Note- Flights and hotel booking are fully refundable before 13th March 2024',
        ],
        inclusion: [
          'Chennai to Bangkok return Flight',
          '4 Nights stay in Bangkok ',
          'Complimentary Breakfast',
          'Airport to Hotel transfer',
          'The Grand Palace admission Cost with transfer',
          'Safari World (Marine plus Safari) with transfer',
          'Siam Water Park with Lunch and transfer',
          'Garden by the Bay ticket',
          'Hotel to Airport',
          'Visa Assistance',
          'Itinerary Assistance',
        ],
        exclusion: ['Anything not mentioned above.'],
        accordionItems: [
          {
            title: '',
            content: '',
            content2: '',
          },        
         
        ]
      },
    },


  ],
  singapore: [
    {
      id: 10,
      place: "singapore",
      imgSrc: "../images/singapore/singapore1.webp",
      altText: "singapore",
      destination: "singapore",
      duration: "4N/5D",
      price: "82,500",
      heading: "heading1",
      details: {
        mainImg: "../../images/singapore/singapore1.jpg",
        moreImg1: "../../images/singapore/singapore2.jpg",
        moreImg2: "../../images/singapore/singapore3.jpg",
        moreImg3: "../../images/singapore/singapore4.jpg",
        moreImg4: "../../images/singapore/singapore5.jpg",
        moreImg5: "../../images/singapore/singapore6.jpg",
        days: '5',
        nights: '4',
        person: 'inclusive of all costs for 2 adults and 1 kid',
        pricePerPerson: "82,500",
        description: [{

          describe: "Immerse yourself in a captivating 5-day journey through the dynamic city-state of Singapore, where each day unveils a new facet of its charm. Begin with a seamless hotel check-in, exploring the futuristic allure of Gardens by the Bay and the iconic Marina Bay Sands. Day two invites you to absorb the rich cultural tapestry by wandering through Chinatown, Little India, and enjoying a captivating performance at the Esplanade.",
        },
        {
          ul: "",
          li: "Explore the thrill of Sentosa Island on Day 3, featuring Universal Studios, beachfront relaxation, and the mesmerizing S.E.A. Aquarium. Uncover Singapore's history and natural beauty on Day 4 with visits to Kampong Glam, the National Museum, Fort Canning Park, and the vibrant Clarke Quay."
        },
        {
          ul: "",
          li: " Bid farewell on Day 5 as you depart for Chennai, carrying with you the treasured memories of a diverse Singaporean experience, brimming with cultural immersion, thrilling attractions, and natural wonders."
        },
        ],
        inclusion: [
          'Chennai to Singapore return Flight',
          'Hotel for 4 nights in Singapore',
          'Visa Assistance',
          'Itinerary Assistance',
        ],
        exclusion: ['Local transport, sightseeing, meals, and anything not mentioned above. '],
        accordionItems: [
          {
            title: "Day 1: Arrival in Singapore",
            content: 'Arrive in Singapore and check into your hotel. Freshen up and have a leisurely breakfast.',
            content2: 'Explore the Gardens by the Bay, featuring the iconic Supertree Grove and Flower Dome'

          },
          {
            title: 'Evening',
            content: 'Visit Marina Bay Sands and enjoy the stunning cityscape from the Skypark.',
            content2: 'Have dinner at one of the many restaurants around Marina Bay.'
          },
          {
            title: 'Day 2: Cultural Exploration',
            content: 'Morning',
            content2: 'Visit Chinatown and explore the vibrant streets, temples, and markets.',
          },
          {
            title: 'Afternoon',
            content: 'Head to Little India for a cultural and ',
            content2: 'culinary experience',
          },        
          {
            title: 'Evening',
            content: 'Attend a cultural performance at Esplanade - Theatres on the Bay',
            content2: ' Explore the nearby waterfront promenade..',
          },        
          {
            title: 'Day 3: Sentosa Island',
            content: 'Take a morning trip to Sentosa Island.',
            content2: '',
          },        
          {
            title: 'Afternoon',
            content: ' Visit Universal Studios Singapore for thrilling rides and entertainment',
            content2: '',
          },        
          {
            title: 'Evening',
            content: 'Relax at one of the beaches on Sentosa or explore the S.E.A. Aquarium.',
            content2: 'Have dinner at one of the beachfront restaurants',
          },        
          {
            title: 'Day 4: Historical and Nature Exploratio',
            content: 'Morning',
            content2: 'Explore the historic district of Kampong Glam, including the Sultan Mosque and Arab Street.',
          },        
          {
            title: 'Afternoon',
            content: 'Visit the National Museum of Singapore to learn about the countrys history.',
            content2: 'Enjoy a stroll in Fort Canning Park',
          },        
          {
            title: 'Evening',
            content: 'Explore Clarke Quay for dinner and riverside entertainment',
            content2: '',
          },        
          {
            title: 'Day 5',
            content: 'Morning',
            content2: ' Departure to Chennai',
          },        
         
        ]
      },
    },


  ],





}
