import React from 'react'
import Form from './Form'
import Navbar from './Navbar2'
import Footer from './Footer'
import Banner from './Banner'

function Adventure() {
  return (
    <>
    <Navbar/>
    <Banner imgData={'../images/adventures.webp'}/> 

    <div className=" section">
    <div className="display_flex ">

<Form days={true} adventures={['para gliding','zip lining','river rafting','para cycling','motorised paragliding','bungee Jumping','Sky Diving']}/>
    </div>
</div>
<Footer/>
    </>
  )
}

export default Adventure