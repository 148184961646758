import React, { useEffect } from 'react';

const Table = ({ data }) => {
  useEffect(() => {
    const existingTable = document.getElementById('yourTableId');

    if (!existingTable) {
      createTable(data);
    } else {
      updateTableContent(existingTable, data);
    }
  }, [data]);

  const createTable = (tableData) => {
    const table = document.createElement('table');
    table.id = 'yourTableId';
    table.classList.add('responsive-table'); // Add a class for responsiveness
    table.style.borderCollapse = 'collapse';
    table.style.marginTop = '20px';
    table.style.border = '1px solid #dddddd';

    // Create the header row
    const headerRow = document.createElement('tr');
    for (let col = 1; col <= tableData[0].length; col++) {
      const th = document.createElement('th');
      th.textContent = `Column ${col}`;
      th.style.border = '1px solid #dddddd';
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    // Create the data rows
    for (let row = 0; row < tableData.length; row++) {
      const dataRow = document.createElement('tr');
      for (let col = 0; col < tableData[row].length; col++) {
        const td = document.createElement('td');
        td.textContent = tableData[row][col];
        td.style.border = '1px solid #dddddd';
        td.style.padding = '4px';
        dataRow.appendChild(td);
      }
      table.appendChild(dataRow);
    }

    const container = document.getElementById('yourSpecificElementId');
    if (container) {
      container.appendChild(table);
    }
  };

  const updateTableContent = (table, tableData) => {
    table.innerHTML = '';
let array=['Date','Day','City of Stay','Temp min/Max','sdActivities','Remarks','Indian Restaurants']
    const headerRow = document.createElement('tr');
    for (let col = 0; col < tableData[0].length; col++) {
      const th = document.createElement('th');
      th.textContent = ` ${array[col]}`;
      th.style.border = '1px solid #dddddd';
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    for (let row = 0; row < tableData.length; row++) {
      const dataRow = document.createElement('tr');
      for (let col = 0; col < tableData[row].length; col++) {
        const td = document.createElement('td');
        td.textContent = tableData[row][col];
        td.style.border = '1px solid #dddddd';
        dataRow.appendChild(td);
      }
      table.appendChild(dataRow);
    }
  };

  return (
    <div id="yourSpecificElementId" style={{width:"100%",overflow:"scroll"}}>
      {/* This div will be the container for the table */}
    </div>
  );
};

export default Table;
