import React, { useState } from 'react'
// import {data} from "../data";
import { Link } from "react-router-dom";
import Navbar2 from "./Navbar2";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { data } from '../data'
import Breadcrumb from '../Breadcrumb';

function Holidays() {
  const { place } = useParams()
  // const dataPages =Object.entries(data).find(([destinationArray])=>destinationArray==place) ;const findDestinationArray = (keyToFind) => {
  function findDestinationArray(place) {

    const foundArray = Object.keys(data).find((key) => key === place);

    if (foundArray) {
      return data[foundArray];
    } else {
      return [];
    }
  }
  const uttrakhandArray = findDestinationArray(place);
console.log(uttrakhandArray)
  return (
    <>
    <Navbar2/>
    <div className="container">

    <Breadcrumb/>
    </div>
<section className="display_flex_col packagePage container">

    <h2 style={{textTransform:"capitalize"}} className='fontBold'>Travel in {place}</h2>
    <p>{uttrakhandArray.length>0?uttrakhandArray.length:'No'} packages available</p>
    <div className="  packagePageSection display_flex  ">
    {uttrakhandArray.length?uttrakhandArray.map((destinationObject) => (
        <div key={destinationObject.id} className='placePackage display_flex_col '> <Link to={`/holidays/${destinationObject.place}/${destinationObject.destination}`} > 
          <img src={`../${destinationObject.imgSrc}`} alt={destinationObject.altText} />
          <div className="display_flex">
            <strong className='place fontBold 'style={{textTransform:"capitalize"}}>{destinationObject.destination}</strong>
            <p className='duration'>{destinationObject.duration}</p>
          </div>
          <div className="display_flex">
            <p className='place' style={{textTransform:"capitalize"}}>{destinationObject.place}</p>
            <p className='price'>₹{destinationObject.price}</p>

          </div>

          {/* Add more properties as needed */}
          </Link>
        </div>
      )):'No package Availbale now'}
      

      {/* <h2>{dataPages.map(item=>item.id)}</h2> */}
      </div>
</section>

<Footer/>
    </>
  )
}

export default Holidays