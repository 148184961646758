import React from 'react'
import Navbar2 from './Navbar2'
import Banner from './Banner'
import Footer from './Footer'
import Form  from './Form'



function Contact() {
  return (
    <>
    <Navbar2/>
    <Banner imgData={'../images/banner2.png'}/>
    <section className="section display_flex contactUs container">
      <div className="display_flex_col">
        <h2 className='heading fontBold'>Get in touch with us to discover new adventures in the world.</h2>
        {/* <p className='subheading'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores maxime voluptatum saepe repellat?</p> */}
        <div className="display_flex my-5">
            <img src="../icons/call.svg" alt="Call Icon" />
            <div className="display_flex_col">
              <strong className='fontMed my-5'>Call Us</strong>
              <p>+91 852 745 5801</p>
            </div>
          </div>
        <div className="display_flex my-5">
            <img src="../icons/mail.svg" alt="Call Icon" />
            <div className="display_flex_col">
              <strong className='fontMed my-5'>Mail Us</strong>
              <p>hello@passtra.com</p>
            </div>
          </div>
      </div>
       <Form/>
    </section>
    <Footer/>
    </>

  )
}

export default Contact