// Breadcrumb.js

import React from 'react';
import { useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();

  // Extract pathname from location
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div style={{marginTop:"10px"}} >
      {/* Render each part of the path as a breadcrumb link */}
      {pathnames.map((part, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        // Define styles for the breadcrumb part
        const breadcrumbStyle = {
            textTransform:"Capitalize",
          color: isLast ? '#4891D4' : 'black', // Set color to blue for the current page
          fontWeight: isLast ? 'bold' : 'normal', // Make font bold for the current page
           // Remove underline for the current page
          cursor: 'pointer', // Add pointer cursor
        };

        return (
          <span key={index} style={breadcrumbStyle} className='fontMed'>
            <span>{part}</span>
            {!isLast && <span> &gt; </span>}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
