import React, { useRef, useState } from 'react';
import { data } from '../data';
import { Link } from 'react-router-dom';
import '../App.scss';

function SearchBar() {
  const [selectedState, setSelectedState] = useState("Select Country");
  const [selectedLocation, setSelectedLocation] = useState("Select Destination");
  // const [selectedPeople, setSelectedPeople] = useState("No. Of People");
  const [selectedUrl, setSelectedUrl] = useState("/");
  const [selectedLocationArray, setLocationArray] = useState({});
  const [isDropDown, setDropDown] = useState(false);
  const [isDropDown2, setDropDown2] = useState(false);
  const [isDropDown3, setDropDown3] = useState(false);
  const [isArroWActive, setArrowActive] = useState(false);
  const [isArroWActive2, setArrowActive2] = useState(false);
  // const [isArroWActive3, setArrowActive3] = useState(false);


  const handleDropDown = () => {
    setDropDown(!isDropDown);
    setDropDown2(false);
    setDropDown3(false);    
    setArrowActive(!isArroWActive);


  };

  const handleDropDown2 = () => {
    setDropDown2(!isDropDown2);
    setDropDown(false);
    setDropDown3(false);setArrowActive2(!isArroWActive2);
  };
  const handleDropDown3 = () => {
    setDropDown3(!isDropDown3);
    setDropDown(false);
    // setDropDown2(false);setArrowActive3(!isArroWActive3);
  };

  function selectedStateFunction(value) {
    setDropDown(!isDropDown);
    setSelectedState(value);
    setSelectedLocation("Select Location")
    findDestinationArray(value);
  }

  function findDestinationArray(place) {
    const foundArray = Object.keys(data).find((key) => key === place);

    if (foundArray) {
      setLocationArray(data[foundArray]);
    } else {
      setLocationArray({});
    }
  }
  
  // console.log(selectedUrl)
function url(location){
  console.log(location,selectedUrl)
  setSelectedUrl(`/holidays/${location}`)
}
  return (
    < >
      
    <section className='searchBar'>
      <div className="display_flex">
        <div>
          <div className="location display_flex">
            <img src="../../icons/snowboarding.svg" alt="" />
            <div className="display_flex_col">
              <p className="fontBold">Country</p>
              <p style={{ textTransform: "capitalize",color:"#757575" }}>{selectedState}</p>
            </div>
            <img src="../../icons/arrow_drop_down.svg" alt="" className={` ${isArroWActive ? 'active' : ''}`}  onClick={handleDropDown} />
          </div>
          <div className={`dropDown ${isDropDown ? 'show' : ''}`}>
            <ul>
              {Object.keys(data).map((item, index) => (
                <li key={index} onClick={() => {selectedStateFunction(item);url(item.toLowerCase())}}style={{textTransform:"capitalize"}}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <div className="location display_flex">
            <img src="../../icons/location_on.svg" alt="" />
            <div className="display_flex_col">
              <p className="fontBold">Destination</p>
              <p style={{ textTransform: "capitalize",color:"#757575" }}>{selectedLocation}</p>
            </div>
            <img src="../../icons/arrow_drop_down.svg" alt="" className={` ${isArroWActive2 ? 'active' : ''}`} onClick={handleDropDown2} />
          </div>
          <div className={`dropDown ${isDropDown2 ? 'show' : ''}`}>
  <ul>
    {!selectedLocationArray.length?<p style={{color:"white"}}>Select Country First</p>:selectedLocationArray.map((item,index)=><li key={index} style={{textTransform:"capitalize"}} onClick={()=>{setSelectedLocation((item.destination.toLowerCase()));setDropDown2(false);url(`${item.place}/${item.destination.toLowerCase()}`)}} >{item.destination}</li>)}
  </ul>
</div>
        </div>
        {/* <div>
          <div className="location display_flex">
            <img src="../../icons/group.png" alt="" />
            <div className="display_flex_col">
              <p className="fontBold">People</p>
              <p style={{ textTransform: "capitalize" }}>{selectedPeople}</p>
            </div>
            <img src="../../icons/arrow_drop_down.svg" alt="" className={` ${isArroWActive3 ? 'active' : ''}`} onClick={handleDropDown3} />
          </div>
          < div className={`dropDown ${isDropDown3 ? 'show' : ''}`}>
  <ul>
  <li onClick={(e)=>{setSelectedPeople(e.currentTarget.innerText);handleDropDown3()}}>1</li>
  <li onClick={(e)=>{setSelectedPeople(e.currentTarget.innerText);handleDropDown3()}}>2</li>
  <li onClick={(e)=>{setSelectedPeople(e.currentTarget.innerText);handleDropDown3()}}>3</li>
  <li onClick={(e)=>{setSelectedPeople(e.currentTarget.innerText);handleDropDown3()}}>4</li>
  </ul>
</div>
        </div> */}
        <div>
          <Link to={selectedUrl} className="btn" id="searchBtn">Search</Link>
        </div>
      </div>
    </section>
    </>

  );
}

export default SearchBar;
