import React from 'react'
import Form from './Form'
import Navbar from './Navbar2'
import Footer from './Footer'
import Banner from './Banner'

function Hotels() {
  return (
    <>
    <Navbar/>
    <Banner imgData={'../images/hotels.webp'}/> 

    <div className=" section">
    <div className="display_flex ">

<Form checkin={true} checkout={true} days={true} date={true}/>
    </div>
</div>
<Footer/>
    </>
  )
}

export default Hotels