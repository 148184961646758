import React from 'react'
import Form from './Form'
import Navbar from './Navbar2'
import Footer from './Footer'
import Banner from './Banner'

function Flights() {
  return (
    <>
    <Navbar/>
    <Banner imgData={'../images/flights.webp'}/> 
    <div className=" section">
    <div className="display_flex ">

<Form departure={true} return={true} days={true} date={true}/>
    </div>
</div>
<Footer/>
    </>
  )
}

export default Flights