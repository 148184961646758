import { React, useState, useRef, useEffect } from 'react'
import ThankYouMessage from './Thanks';

function Form(props) {
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);

  const email = useRef(null);
  const emailElement = email.current;
  const contact = useRef(null);
  const contactElement = contact.current;
  const form = useRef(null);
  const formElement = contact.current;
  const [formData, setFormData] = useState({
    name: '',
    destination: '',
    days: '',
    people: '',
    date: '',
    contact: '',
    email: '',
    message: '',
    pickup: '',
    checkin:'',
    checkout:'',
    departureDate:'',
    returnDate:'',
    adventureType:''
  });
  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState({
    // email: '',
    // contact: '',
    // details: '',
  });

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbyKyoBj94Zr20mvPOBkraxZi-lPYHJINIIck7NgCVYDzvJaHiZntGVLhdZJL7FipFmf/exec', {
        method: 'POST',
        mode: 'cors', // Add this line
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      if (response.ok) {
        setShowForm(false);
        setShowThankYou(true);
        console.log('Form data submitted successfully', formData);
        // Optionally, reset the form after successful submission
        setFormData({
          name: '',
          destination: '',
          days: '',
          people: '',
          date: '',
          contact: '',
          email: '',
          message: '',
          pickup: '',
          checkin:'',
          checkout:'',
          departureDate:'',
          returnDate:'',
          adventureType:''

        });
        form.current.reset();
        setTimeout(() => {
          setShowForm(true);
          setShowThankYou(false);
        }, 2000);
      } else {
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <div className="formDiv display_flex_col">
      <h3 className='my-5'>Let the adventure begin!</h3>
      <p className='my-5'>Fill the below form and leave the rest on us!</p>
      {showForm ? (
        <form className="form display_flex" ref={form} >
          <div className="errorMessage"></div>

          <div className='display_flex_col'>

            <label className='fontMed' htmlFor="name">Name</label>
            <input type="text" id='name' name="Name" placeholder='Your Name' onChange={handleInputChange} />
          </div>
          {props.adventures ?
          <div className='display_flex_col'>

            <label className='fontMed' htmlFor="adventureType">Adventure Type</label>
    <select name="Adventure Type" id="adventureType" onChange={handleInputChange} style={{ padding: '5px 10px', backgroundColor: 'white', textTransform:"capitalize",border:"none",width:"100%",color:"#757575",fontSize:"18px",fontFamily:"league",}}>
        {props.adventures.map((item, index) =>
            <option value={item} key={index} style={{textTransform:"capitalize"}} >{item}</option>
            )}
    </select> 
            </div>
    : null
}

          {props.pickup ? <div className='display_flex_col'>

            <label className='fontMed' htmlFor="desination">Pick Up Location</label>
            <input type="text" id='pickupdestination' name="Pick Up Location" placeholder='Your Pickup' onChange={handleInputChange} />
          </div> : null}

          <div className='display_flex_col'>

            <label className='fontMed' htmlFor="destination">{props.destination ? 'Drop Off Location' : 'Destination'}</label>
            <input type="text" id='destination' name={props.destination ? 'Drop Off Location' : 'Destination'} placeholder='Your Drop Off' onChange={handleInputChange} />
          </div>
          <div className="display_flex">{
            props.days? null :
              <div className='display_flex_col'>

                <label className='fontMed' htmlFor="days">Days</label>
                <input type="text" id='days' name="Days" placeholder='Trip duration' onChange={handleInputChange} />
              </div>
          }
            <div className='display_flex_col'>

              <label className='fontMed' htmlFor="people">People</label>
              <input type="text" id='people' name="People" placeholder='No. of Travellers' onChange={handleInputChange} />
            </div>
          </div>
          {props.departure ?
            <div className="display_flex">
              <div className='display_flex_col'>

                <label className='fontMed' htmlFor="departureDate">Departure Date</label>
                <input type="date" id='departureDate' name="Departure Date" placeholder='Departure Date' onChange={handleInputChange} />
              </div>

              <div className='display_flex_col'>

                <label className='fontMed' htmlFor="returnDate">Return Date</label>
                <input type="date" id='returnDate' name="Return Date" placeholder='Return Date' onChange={handleInputChange} />
              </div>
            </div>
            : null}
          {props.checkin ?
            <div className="display_flex">
              <div className='display_flex_col'>

                <label className='fontMed' htmlFor="checkin">Check In Date</label>
                <input type="date" id='checkin' name="Check In" placeholder='Checkin Date' onChange={handleInputChange} />
              </div>

              <div className='display_flex_col'>

                <label className='fontMed' htmlFor="checkout">Check Out Date</label>
                <input type="date" id='checkout' name="Check Out" placeholder='Checkout Date' onChange={handleInputChange} />
              </div>
            </div>
            : null}
          {props.date ? null : <div className='display_flex_col'>

            <label className='fontMed' htmlFor="date">Date</label>
            <input type="date" id='date' name="Date" placeholder='Date of trip' onChange={handleInputChange} />
          </div>}
            {/* {props.} */}
          <div className='display_flex_col'>

            <label className='fontMed' htmlFor="contact">Contact</label>
            <input type="text" id='contact' name="Contact" placeholder='Your phone number' ref={contact} onChange={handleInputChange} />

            <div className="errorMessage"></div>

          </div>
          <div className='display_flex_col'>

            <label className='fontMed' htmlFor="email">E mail</label>
            <input type="email" id='email' name="Email" placeholder='Your email address' ref={email} onChange={handleInputChange} />

            <div className="errorMessage"></div>

          </div>
          <div className='display_flex_col'>

            <label className='fontMed' htmlFor="message">Message</label>
            <input type="text" id='message' name="Message" onChange={handleInputChange} />
          </div>
          <button type='submit' className='btn submit my-5' onClick={handleSubmit}>Submit</button>
        </form>
      ) : (
        showThankYou && <ThankYouMessage />
      )}
    </div>
  )
}

export default Form