import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <footer className="footer ">
    <div className="display_flex container">
        <div className="display_flex_col">
            <img src="../../images/logoBlack.svg" alt="" className="footerLogo"/>
            <p style={{maxWidth: "300px"}}>A leading travel agency, specializes in curating bespoke travel packages that cater to the wanderlust within you. With a team of seasoned travel enthusiasts and a wealth of experience in the industry, we take pride in turning your travel dreams into reality.</p>
            <div className="display_flex_xol">
                <p className='fontMed'>Follow us on social media</p>
                <div className="display_flex">
                    <Link to={'https://www.instagram.com/passtraholidays/'} target='_blank'>
                    <img src="icons/insta.svg" alt="" className="socialIcons"/>
                    </Link>
                    <Link to={'https://www.facebook.com/profile.php?id=61555340135513'} target='_blank'>
                    <img src="icons/fb.svg" alt="" className="socialIcons"/>
                    </Link>
                    <img src="icons/linkedIn.svg" alt="" className="socialIcons"/>
                    <Link to={'https://twitter.com/PasstraHolidays'} target='_blank'>
                    <img src="icons/x.svg" alt="" className="socialIcons"/>
                    </Link>
                </div>
            </div>
        </div>
        <div className="display_flex_col">
            <p className="fontBold">Useful links</p>
            <ul>
                <li><Link to={'/about'}>About us</Link></li>
                <li><Link to={'/contact'}>Contact Us</Link></li>
                <li><Link to={'/privacypolicy'}>Privacy Policy</Link></li>
                <li><Link to={'/termsconditions'}>Terms and Condition</Link></li>
            </ul>
        </div>
        <div className="display_flex_col">
            <p className="fontBold">Destination</p>
            <ul>
            {/* <li><Link to={`/holidays/uttrakhand`} >Uttrakhand</Link></li> */}
             <li><Link to={`/holidays/india`} >India</Link></li>
             <li><Link to={'/holidays/europe/europe'} >Europe</Link></li>
             <li><Link to={'/holidays/kazakhstan/kazakhstan'} >Kazakhstan</Link></li>
             <li><Link to={'/holidays/singapore/singapore'} >Singapore</Link></li>
             <li><Link to={'/holidays/thailand/thailand'} >Thailand</Link></li>
            </ul>
        </div>
        <div className="display_flex_col">
            <p className="fontBold">Subscribe to our news letter</p>
            <p>Join our mail list to receive updates and exclusive offers and discounts.</p>
            <form action="" className="display_flex">
                
                <input type="email" placeholder="email address" style={{border:"1px solid #4891D4",width:"80%"}}/>
            
                <button type="submit " className="btn ">Submit</button>
            </form>
            <p>By subscribing, you agree to our privacy policy and consent to receive updated from us.</p>
        </div>
    </div>
</footer>
    <div className="copyrightStrip display_flex_col" style={{background:"#D9D9D9",padding:"5px 10px"}}>
        <p><Link to={'http://thejmedia.in/'} target='_blank' style={{color:"black"}}>Copyright © | The J Media</Link></p>
    </div>
    </>
  )
}

export default Footer