import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const DropdownMenu = () => {
    const [isDropDown, setDropDown] = useState(false);
    const [isSubDropDown, setSubDropDown] = useState(false);

    const handleDropDown = () => {
        setDropDown(!isDropDown);
        // Close sub-dropdown when main dropdown is toggled
        setSubDropDown(false);
    };

    const handleSubDropDown = () => {
        setSubDropDown(!isSubDropDown);
    };

    return (
        <div className="dropdown">
            <button className="dropbtn" onClick={handleDropDown}>Dropdown</button>
            <div className={`dropdown-content ${isDropDown ? 'show' : ''}`}>
                <Link to="/home">Home</Link>
                <Link to="/about">About</Link>
                <div className="sub-dropdown">
                    <button className="sub-dropbtn" onClick={handleSubDropDown}>Sub Dropdown</button>
                    <div className={`sub-dropdown-content ${isSubDropDown ? 'show' : ''}`}>
                        <Link to="/sub1">Sub Option 1</Link>
                        <Link to="/sub2">Sub Option 2</Link>
                        <Link to="/sub3">Sub Option 3</Link>
                    </div>
                </div>
                <Link to="/contact">Contact</Link>
            </div>
        </div>
    );
};

export default DropdownMenu;
