import React from 'react'
import Navbar2 from './Navbar2'
import Footer from './Footer'

function Offers() {
  return (
    <>
    <Navbar2/>
    <h1>Coming Soon</h1>
    <Footer/>
    </>
  )
}

export default Offers