import React, { useState } from 'react';
import '../App.scss'
import '../responsive.scss'
const ImageGallery = ({ mainImg, moreImg1, moreImg2, moreImg3, moreImg4, moreImg5 }) => {
  const [currentImage, setCurrentImage] = useState(mainImg);

  const handleImageClick = (newImage) => {
    setCurrentImage(newImage);
  };

  return (
    <>
      <img src={currentImage} alt="Main Image" className='mainImg'/>

      <div className="display_flex">
        <img
          src={moreImg1}
          alt="Thumbnail 1"
          className="moreImg"
          onClick={() => handleImageClick(moreImg1)}
        />
        <img
          src={moreImg2}
          alt="Thumbnail 2"
          className="moreImg"
          onClick={() => handleImageClick(moreImg2)}
        />
        <img
          src={moreImg3}
          alt="Thumbnail 3"
          className="moreImg"
          onClick={() => handleImageClick(moreImg3)}
        />
        <img
          src={moreImg4}
          alt="Thumbnail 4"
          className="moreImg"
          onClick={() => handleImageClick(moreImg4)}
        />
        <img
          src={moreImg5}
          alt="Thumbnail 5"
          className="moreImg"
          onClick={() => handleImageClick(moreImg5)}
        />
      </div>
    </>
  );
};

export default ImageGallery;
