import React, { useState } from 'react';

const GoogleForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await fetch('https://script.google.com/macros/s/AKfycbyKyoBj94Zr20mvPOBkraxZi-lPYHJINIIck7NgCVYDzvJaHiZntGVLhdZJL7FipFmf/exec', {
            method: 'POST',
            mode: 'cors', // Add this line
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData).toString(),
          });

      if (response.ok) {
        console.log('Form data submitted successfully');
        // Optionally, reset the form after successful submission
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <div>
      <h2>Contact Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="Name" value={formData.name} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Email:
          <input type="email" name="Email" value={formData.email} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Message:
          <textarea name="Message" value={formData.message} onChange={handleInputChange} />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default GoogleForm;
