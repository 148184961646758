import React from 'react';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, backgroundImage: 'url(../icons/arrow_left.svg))' }}
      onClick={onClick}
    />
  );
};

export default CustomPrevArrow;
